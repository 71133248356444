import { QueryClient } from '@tanstack/react-query';

const defaultQueryConfig = {
  staleTime: 0,
  cacheTime: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const queryClient = new QueryClient({
  defaultOptions: { queries: defaultQueryConfig },
});
