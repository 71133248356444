import { INTERVIEWS_QUERY_KEY } from '@constants/index';
import { loadInterview } from '@features/schedule/api';
import { useAppStore } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useGetInterview(interviewId: string) {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  return useQuery({
    queryKey: [INTERVIEWS_QUERY_KEY, interviewId],
    queryFn: async () => loadInterview(spaceId, interviewId),
  });
}
