import * as React from 'react';
import { useSignIn } from '@features/auth/api/hooks';
import { SignInInput } from '@features/auth/api/input';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

export const SignInForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const [form] = Form.useForm<SignInInput>();

  const signIn = useSignIn();

  const onFinish = async (values: SignInInput) => {
    signIn.mutate(values);
  };

  return (
    <Form
      name="normal_login"
      form={form}
      layout="vertical"
      initialValues={{
        email: '',
        password: '',
      }}
      onFinish={onFinish}
      requiredMark={false}
    >
      <Form.Item
        name="email"
        label={t('email')}
        rules={[
          {
            type: 'email',
            message: t('email-is-not-valid'),
          },
          {
            required: true,
            message: t('email-is-required'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('password')}
        rules={[{ required: true, message: t('password-is-required') }]}
      >
        <Input.Password type="password" />
      </Form.Item>

      {/*<Form.Item style={{ textAlign: 'right' }}>*/}
      {/*  <a className="login-form-forgot" href="">*/}
      {/*    Forgot password?*/}
      {/*  </a>*/}
      {/*</Form.Item>*/}

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={signIn.isLoading}
        >
          {t('sign-in')}
        </Button>
      </Form.Item>
    </Form>
  );
};
