import React from 'react';
import { Auth } from '@features/auth';
import { AuthenticationForm } from '@features/auth/components/authentication-form';
import { RouteObject } from 'react-router-dom';
import { CompleteRegistration } from 'src/features/auth/components/complete-registration';

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: <Auth />,
    children: [
      {
        index: true,
        path: 'sign-in?',
        element: <AuthenticationForm activeTabKey={'sign-in'} />,
      },
      {
        path: 'sign-up',
        element: <AuthenticationForm activeTabKey={'sign-up'} />,
      },
      {
        path: 'spaces',
        element: <CompleteRegistration />,
      },
    ],
  },
];
