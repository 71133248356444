import React from 'react';
import { InterviewQuestionResult, VacancyQuestion } from '@features/vacancies/types';
import { DEFAULT_MODEL, openai } from '@openai/index';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

interface ChatQuestion {
  question: string;
  answer: string;
  tips: string;
}

export const useQuestionGenerator = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'openai' });
  const [isLoading, setIsLoading] = React.useState(false);
  const [questions, setQuestions] = React.useState<VacancyQuestion[]>([]);

  const generate = async (title: string, description: string) => {
    setIsLoading(true);

    const completion = await openai.chat.completions.create({
      model: DEFAULT_MODEL,
      messages: [
        {
          role: 'system',
          content: t('question-system-content'),
        },
        {
          role: 'user',
          content: t('question-user-content', { title: title, description: description }),
        },
      ],
    });

    if (completion.choices[0].message.content) {
      const content: ChatQuestion[] = JSON.parse(completion.choices[0].message.content);

      setQuestions(
        content.map((value) => ({
          id: v4().toString(),
          question: {
            content: value.question,
          },
          answer: {
            content: value.answer,
          },
          tips: {
            content: value.tips,
          },
        })),
      );
    }

    setIsLoading(false);
  };

  return { isLoading, questions, generate };
};

export const useFeedbackGenerator = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'openai' });
  const [isLoading, setIsLoading] = React.useState(false);
  const [feedback, setFeedback] = React.useState<string>('');

  const generate = async (interviewQuestions: InterviewQuestionResult[]) => {
    setIsLoading(true);

    const completion = await openai.chat.completions.create({
      model: DEFAULT_MODEL,
      messages: [
        {
          role: 'system',
          content: t('feedback-system-content'),
        },
        {
          role: 'user',
          content: interviewQuestions
            .map((value) =>
              t('feedback-user-content', {
                question: value.question.question.content,
                score: value.score,
                notes: value.notes,
                skipped: value.skipped,
              }),
            )
            .join('\n'),
        },
      ],
      stream: true,
    });

    for await (const chunk of completion) {
      if (chunk.choices[0].delta.content !== undefined && chunk.choices[0].delta.content !== null) {
        setFeedback((prevState) => `${prevState}${chunk.choices[0].delta.content}`);
      }
    }

    setIsLoading(false);
  };

  return { isLoading, feedback, generate };
};
