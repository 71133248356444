import { signUp } from '@features/auth/api';
import { useSignIn } from '@features/auth/api/hooks/use-sign-in';
import { SignUpInput } from '@features/auth/api/input';
import { AuthTokenOutput } from '@features/auth/api/output';
import { useMutation } from '@tanstack/react-query';

export function useSignUp() {
  const signIn = useSignIn();

  return useMutation<AuthTokenOutput, unknown, SignUpInput, unknown>(
    (data: SignUpInput) => signUp(data),
    {
      onSuccess: (_data, variables) => {
        signIn.mutate(variables);
      },
    },
  );
}
