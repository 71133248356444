import { instance } from '@api/index';
import { PaginatedResponse } from '@common/api/output';
import { DEFAULT_PAGE_SIZE } from '@constants/index';
import {
  AddVacancyCandidateInput,
  AddVacancyInterviewInput,
  FinishVacancyInterviewInput,
  UpdateVacancyCandidateInput,
  UpdateVacancyInput,
  UpdateVacancyInterviewInput,
  UpdateVacancyQuestionInput,
  VacancyInput,
  VacancyQuestionInput,
} from '@features/vacancies/api/input';
import { CandidateOutput, InterviewOutput, VacancyOutput } from '@features/vacancies/api/output';

const VACANCIES_ENDPOINT = 'vacancies';
const QUESTIONS_ENDPOINT = 'questions';
const INTERVIEWS_ENDPOINT = 'interviews';
const CANDIDATES_ENDPOINT = 'candidates';

export async function loadVacancies(
  spaceId: string,
  page: number,
  limit: number = DEFAULT_PAGE_SIZE,
): Promise<PaginatedResponse<VacancyOutput>> {
  const offset = page * limit;

  const { data } = await instance.get<PaginatedResponse<VacancyOutput>>(
    `${spaceId}/${VACANCIES_ENDPOINT}`,
    {
      params: {
        limit: limit,
        offset: offset,
      },
    },
  );

  return data;
}

export async function createVacancy(spaceId: string, input: VacancyInput): Promise<never> {
  const { data } = await instance.post<Promise<never>>(`${spaceId}/${VACANCIES_ENDPOINT}`, input);

  return data;
}

export async function loadVacancy(spaceId: string, vacancyId: string): Promise<VacancyOutput> {
  const { data } = await instance.get<Promise<VacancyOutput>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}`,
  );

  return data;
}

export async function updateVacancy(
  spaceId: string,
  vacancyId: string,
  input: UpdateVacancyInput,
): Promise<never> {
  const { data } = await instance.put<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}`,
    input,
  );

  return data;
}

export async function deleteVacancy(spaceId: string, vacancyId: string): Promise<never> {
  const { data } = await instance.delete<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}`,
  );

  return data;
}

export async function addVacancyQuestion(
  spaceId: string,
  vacancyId: string,
  input: VacancyQuestionInput,
): Promise<never> {
  const { data } = await instance.post<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${QUESTIONS_ENDPOINT}`,
    input,
  );

  return data;
}

export async function updateVacancyQuestion(
  spaceId: string,
  vacancyId: string,
  questionId: string,
  input: UpdateVacancyQuestionInput,
): Promise<never> {
  const { data } = await instance.put<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${QUESTIONS_ENDPOINT}/${questionId}`,
    input,
  );

  return data;
}

export async function deleteVacancyQuestion(
  spaceId: string,
  vacancyId: string,
  questionId: string,
): Promise<never> {
  const { data } = await instance.delete<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${QUESTIONS_ENDPOINT}/${questionId}`,
  );

  return data;
}

export async function loadVacancyInterviews(
  spaceId: string,
  vacancyId: string,
  page: number,
): Promise<PaginatedResponse<InterviewOutput>> {
  const offset = page * DEFAULT_PAGE_SIZE;

  const { data } = await instance.get<PaginatedResponse<InterviewOutput>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${INTERVIEWS_ENDPOINT}`,
    {
      params: {
        limit: DEFAULT_PAGE_SIZE,
        offset: offset,
      },
    },
  );

  return data;
}

export async function assignVacancyInterview(
  spaceId: string,
  vacancyId: string,
  input: AddVacancyInterviewInput,
): Promise<never> {
  const { data } = await instance.post<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${INTERVIEWS_ENDPOINT}`,
    input,
  );

  return data;
}

export async function updateVacancyInterview(
  spaceId: string,
  vacancyId: string,
  interviewId: string,
  input: UpdateVacancyInterviewInput,
): Promise<never> {
  const { data } = await instance.put<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${INTERVIEWS_ENDPOINT}/${interviewId}`,
    input,
  );

  return data;
}

export async function finishVacancyInterview(
  spaceId: string,
  vacancyId: string,
  interviewId: string,
  input: FinishVacancyInterviewInput,
): Promise<never> {
  const { data } = await instance.put<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${INTERVIEWS_ENDPOINT}/${interviewId}/status`,
    input,
  );

  return data;
}

export async function deleteVacancyInterview(
  spaceId: string,
  vacancyId: string,
  interviewId: string,
): Promise<never> {
  const { data } = await instance.delete<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${INTERVIEWS_ENDPOINT}/${interviewId}`,
  );

  return data;
}

export async function loadVacancyCandidates(
  spaceId: string,
  vacancyId: string,
  page: number,
): Promise<PaginatedResponse<CandidateOutput>> {
  const offset = page * DEFAULT_PAGE_SIZE;

  const { data } = await instance.get<PaginatedResponse<CandidateOutput>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${CANDIDATES_ENDPOINT}`,
    {
      params: {
        limit: DEFAULT_PAGE_SIZE,
        offset: offset,
      },
    },
  );

  return data;
}

export async function addVacancyCandidate(
  spaceId: string,
  vacancyId: string,
  input: AddVacancyCandidateInput,
): Promise<never> {
  const { data } = await instance.post<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${CANDIDATES_ENDPOINT}`,
    input,
  );

  return data;
}

export async function updateVacancyCandidate(
  spaceId: string,
  vacancyId: string,
  candidateId: string,
  input: UpdateVacancyCandidateInput,
): Promise<never> {
  const { data } = await instance.put<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${CANDIDATES_ENDPOINT}/${candidateId}`,
    input,
  );

  return data;
}

export async function deleteVacancyCandidate(
  spaceId: string,
  vacancyId: string,
  candidateId: string,
): Promise<never> {
  const { data } = await instance.delete<Promise<never>>(
    `${spaceId}/${VACANCIES_ENDPOINT}/${vacancyId}/${CANDIDATES_ENDPOINT}/${candidateId}`,
  );

  return data;
}

export async function loadCandidate(
  spaceId: string,
  candidateId: string,
): Promise<CandidateOutput> {
  const { data } = await instance.get<CandidateOutput>(
    `${spaceId}/${CANDIDATES_ENDPOINT}/${candidateId}`,
  );

  return data;
}
