import React from 'react';
import { useGetInterviews } from '@features/schedule/api/hooks';
import { InterviewList } from '@features/schedule/components/list';
import { theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const ScheduledInterviews: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });
  const { token } = theme.useToken();
  const { isLoading, data } = useGetInterviews();

  return (
    <>
      <Typography.Title level={4}>
        {t('scheduled-interviews')}{' '}
        <span style={{ color: token.colorTextTertiary }}>{data?.length ?? 0}</span>
      </Typography.Title>
      <InterviewList isLoading={isLoading} items={data} />
    </>
  );
};
