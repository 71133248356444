import { CANDIDATES_QUERY_KEY, INTERVIEWS_QUERY_KEY, VACANCIES_QUERY_KEY } from '@constants/index';
import { finishVacancyInterview } from '@features/vacancies/api';
import { FinishVacancyInterviewInput } from '@features/vacancies/api/input';
import { useAppStore } from '@store/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useFinishInterview() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    {
      vacancyId: string;
      interviewId: string;
      candidateId: string;
      input: FinishVacancyInterviewInput;
    },
    unknown
  >(
    ({ vacancyId, interviewId, input }) =>
      finishVacancyInterview(spaceId, vacancyId, interviewId, input),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries({ queryKey: [CANDIDATES_QUERY_KEY] });
        queryClient.invalidateQueries({ queryKey: [INTERVIEWS_QUERY_KEY] });
        queryClient.invalidateQueries({
          queryKey: [VACANCIES_QUERY_KEY, variables.vacancyId, CANDIDATES_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [VACANCIES_QUERY_KEY, variables.vacancyId, CANDIDATES_QUERY_KEY],
        });
      },
    },
  );
}
