import { createAuthSlice } from '@features/auth/stores';
import { createTemplateSlice } from '@features/templates/stores';
import { createConductInterviewSlice } from '@features/vacancies/components/interview/stores';
import { AppStore } from '@store/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useAppStore = create<AppStore>()(
  persist(
    (...set) => ({
      ...createTemplateSlice(...set),
      ...createAuthSlice(...set),
      ...createConductInterviewSlice(...set),
    }),
    {
      name: 'devview-store',
    },
  ),
);
