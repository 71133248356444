import React from 'react';
import { queryClient } from '@api/query-client';
import { DataProvider } from '@context/data';
import { DrawerProvider } from '@context/drawer';
import { refreshApi } from '@features/auth/api';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { config } from '@theme/config';
import { App as AppProvider, ConfigProvider, Layout } from 'antd';
import { Locale } from 'antd/lib/locale';
import enUS from 'antd/locale/en_US';
import ruRU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { AuthProvider } from 'react-auth-kit';
import { Outlet } from 'react-router-dom';

function App() {
  let locale: Locale;

  switch (i18next.resolvedLanguage) {
    case 'en':
      locale = enUS;
      break;
    case 'ru':
      locale = ruRU;
      break;
    default:
      locale = enUS;
  }

  dayjs.locale(locale.locale);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={config} locale={locale}>
        <AuthProvider authType={'localstorage'} authName={'_auth'} refresh={refreshApi}>
          <AppProvider>
            <DataProvider>
              <DrawerProvider>
                <React.Suspense>
                  <Layout>
                    <Outlet />
                  </Layout>
                </React.Suspense>
              </DrawerProvider>
            </DataProvider>
          </AppProvider>
        </AuthProvider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
