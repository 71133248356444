import React, { useEffect } from 'react';
import { DrawerProps, useDrawer } from '@context/drawer';
import { useAssignEntityTag } from '@features/tags/api/hooks/use-assign-entity-tag';
import { useCreateTemplate, useUpdateTemplate } from '@features/templates/api/hooks';
import { TemplateForm, TemplateFormType } from '@features/templates/components/form';
import { TemplateFormActionType, TemplateType } from '@features/templates/types';
import { App, Button, Form, Space } from 'antd';
import { ModalFuncProps } from 'antd/es/modal/interface';
import { useTranslation } from 'react-i18next';

const DRAWER_ID = 'ManageTemplate';

export const useManageTemplate = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'templates.hooks' });
  const [form] = Form.useForm<TemplateFormType>();
  const { modal, notification } = App.useApp();
  const drawer = useDrawer();

  const {
    isLoading: createIsLoading,
    mutate: createTemplate,
    isSuccess: createIsSuccess,
  } = useCreateTemplate();

  const {
    isLoading: updateIsLoading,
    mutate: updateTemplate,
    isSuccess: updateIsSuccess,
  } = useUpdateTemplate();

  const { mutate: assignTag } = useAssignEntityTag();

  const clearState = () => {
    form.resetFields();
    if (drawer.id === DRAWER_ID) {
      drawer.closeDrawer();
    }
  };

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess) {
      clearState();
      notification.success({
        message: t('message'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createIsSuccess, updateIsSuccess]);

  return (
    contextDialogType: 'drawer' | 'modal',
    type: TemplateFormActionType,
    item?: TemplateType,
    onSubmit?: (template: TemplateType) => void,
  ) => {
    item && form.setFieldsValue({ ...item, tags: [] });

    const onFinish = (values: TemplateFormType) => {
      switch (type) {
        case 'create':
          createTemplate({ ...values, ratingScale: values.ratingScale.type });
          break;
        case 'edit':
          item &&
            updateTemplate({
              id: values.id,
              data: { ...values, ratingScale: values.ratingScale.type },
            });
          break;
      }

      if (values.tags && values.tags.length > 0) {
        values.tags.forEach((tag) => {
          assignTag({
            entityId: values.id,
            entityType: 'template',
            tagId: tag,
          });
        });
      }

      if (onSubmit) {
        onSubmit(values);
      }
    };

    const props: DrawerProps & ModalFuncProps = {
      id: DRAWER_ID,
      title: type === 'create' ? t('create-template') : t('edit-template'),
      extra: (
        <Space>
          <Button onClick={clearState}>{t('cancel')}</Button>
          <Button
            htmlType="submit"
            onClick={() => form.submit()}
            type="primary"
            loading={createIsLoading || updateIsLoading}
          >
            {type === 'create' ? t('submit') : t('save')}
          </Button>
        </Space>
      ),
      content: <TemplateForm form={form} onFinish={onFinish} />,
      size: 'large',
      width: '1000px',
      closable: true,
      icon: null,
      okText: type === 'create' ? t('submit') : t('save'),
      onOk: () => form.submit(),
    };

    switch (contextDialogType) {
      case 'drawer':
        drawer.openDrawer({ ...props });
        break;
      case 'modal':
        modal.info({ ...props });
        break;
    }
  };
};
