import React, { Fragment } from 'react';
import { useGetSpace, useGetUser } from '@features/auth/api/hooks';

interface DataProviderProps {
  children: React.ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  useGetUser();
  useGetSpace();

  return <Fragment>{children}</Fragment>;
};
