import React, { useEffect, useState } from 'react';
import { CloseOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CollapsibleCard } from '@common/components/card';
import { Tag } from '@features/tags';
import { TagSelector } from '@features/tags/components/selector';
import { RatingScaleOptionsFactory } from '@features/templates/domain';
import { RatingScaleEnum, RatingScaleType } from '@features/templates/types';
import { useQuestionGenerator } from '@openai/hooks';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

type ContentFormType = {
  content: string;
};

type QuestionFormType = {
  id: string;
  question: ContentFormType;
  answer?: ContentFormType | undefined | null;
  tips?: ContentFormType | undefined | null;
};

export type TemplateFormType = {
  id: string;
  title: string;
  description?: ContentFormType | undefined | null;
  tags?: string[];
  questions: QuestionFormType[];
  ratingScale: RatingScaleType;
};

export interface TemplateFormProps {
  form: FormInstance<TemplateFormType>;
  onFinish: (values: TemplateFormType) => void;
}

export const TemplateForm: React.FC<TemplateFormProps> = ({ form, onFinish }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'templates.form' });
  const questionGenerator = useQuestionGenerator();

  useEffect(() => {
    if (questionGenerator.questions.length > 0) {
      const previousQuestions = form.getFieldValue('questions') ?? [];

      form.setFieldsValue({
        questions: questionGenerator.questions.concat(previousQuestions),
      });
    }
  }, [form, questionGenerator.questions]);

  const generateQuestions = async () => {
    const title = form.getFieldValue('title');
    const description = form.getFieldValue(['description', 'content']);

    await questionGenerator.generate(title, description);
  };

  let initialRatingScaleValue = form.getFieldValue(['ratingScale', 'type']);

  if (initialRatingScaleValue === undefined) {
    form.setFieldValue(['ratingScale', 'type'], RatingScaleEnum.PERCENT);
    initialRatingScaleValue = RatingScaleEnum.PERCENT;
  }

  const [selectedRatingScaleType, setSelectedRatingScaleType] =
    useState<RatingScaleEnum>(initialRatingScaleValue);
  const ratingScaleOptionsFactory = new RatingScaleOptionsFactory();

  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Form.Item name={'id'} initialValue={v4().toString()} hidden={true} />

      <Form.Item
        name={'title'}
        label={t('title')}
        rules={[{ required: true, message: t('title-is-required') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name={['description', 'content']} label={t('description')}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item hidden={true} name={['description', 'fileIds']} />

      <Form.Item
        name={['ratingScale', 'type']}
        label={t('rating-scale')}
        rules={[{ required: true, message: t('rating-scale-is-required') }]}
      >
        <Typography.Paragraph style={{ fontSize: 13 }}>
          {t('rating-scale-info')}
        </Typography.Paragraph>
        <Radio.Group
          defaultValue={selectedRatingScaleType}
          onChange={({ target: { value } }: RadioChangeEvent) => {
            setSelectedRatingScaleType(value);
            form.setFieldsValue({
              ratingScale: {
                type: value,
                options: ratingScaleOptionsFactory.create(value).map((item) => ({
                  scale: item.scale,
                  value: item.value,
                })),
              },
            });
          }}
        >
          <Space direction={'vertical'}>
            {Object.entries(RatingScaleEnum).map((value, key) => (
              <>
                <Radio key={key} value={value[1]}>
                  <Space direction={'vertical'}>
                    {t(`rating-scale-label-${value[1]}`)}
                    <div style={{ width: '100%' }}>
                      {ratingScaleOptionsFactory.create(value[1]).map(({ value }, index) => (
                        <Tag key={index}>{value}</Tag>
                      ))}
                    </div>
                  </Space>
                </Radio>
              </>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>

      {/* TODO: Custom rating scale in version 1 doesn't exists. */}
      <div style={{ display: 'none' }}>
        <Form.List name={['ratingScale', 'options']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} style={{ alignItems: 'baseline' }} gutter={16}>
                  <Col span={11}>
                    <Form.Item {...restField} name={[name, 'scale']}>
                      <InputNumber
                        style={{ width: '100%' }}
                        max={100}
                        min={10}
                        step={10}
                        placeholder={t('rating-scale-score')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item {...restField} name={[name, 'value']}>
                      <Input placeholder={t('rating-scale-value')} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {t('rating-scale-add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>

      <Form.Item name={'tags'} label={t('tags')}>
        <TagSelector
          value={form.getFieldValue('tags')}
          onChange={(value) => {
            form.setFieldValue('tags', value);
          }}
        />
      </Form.Item>

      <Button
        style={{ marginBottom: 16 }}
        icon={<EditOutlined />}
        loading={questionGenerator.isLoading}
        onClick={generateQuestions}
      >
        {t('generate-questions')}
      </Button>

      <Form.List
        name={'questions'}
        rules={[
          {
            validator: async (_, questions) => {
              if (!questions || questions.length < 1) {
                return Promise.reject(new Error(t('question-min-required')));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
            {fields.map((field) => (
              <CollapsibleCard
                key={field.key}
                title={`${field.name + 1}. ${t('question')}`}
                size={'small'}
                extra={
                  <CloseOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
              >
                <Form.Item name={[field.name, 'id']} initialValue={v4().toString()} hidden={true} />

                <Form.Item
                  name={[field.name, 'question', 'content']}
                  label={t('question')}
                  rules={[{ required: true, message: t('question-is-required') }]}
                >
                  <Input.TextArea autoSize />
                </Form.Item>

                <Form.Item name={[field.name, 'answer', 'content']} label={t('answer')}>
                  <Input.TextArea autoSize />
                </Form.Item>

                <Form.Item name={[field.name, 'tips', 'content']} label={t('tips')}>
                  <Input.TextArea autoSize />
                </Form.Item>

                <Form.Item name={[field.name, 'isDeleted']} hidden={true} initialValue={false} />
              </CollapsibleCard>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('question-add-question')}
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </div>
        )}
      </Form.List>
    </Form>
  );
};
