import React from 'react';
import { Candidate } from '@features/vacancies/components/candidates/types';
import { ConductInterviewResult } from '@features/vacancies/components/interview/form';
import { ConductInterviewResultForm } from '@features/vacancies/components/interview/types';
import { InterviewResult } from '@features/vacancies/types';
import { App, Form } from 'antd';
import { useTranslation } from 'react-i18next';

export const useShowInterviewResult = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.interview.hooks' });
  const { modal } = App.useApp();

  const [form] = Form.useForm<ConductInterviewResultForm>();

  return (candidate: Candidate, interviewResult: InterviewResult) => {
    form.setFieldValue('feedback', interviewResult.feedback);

    modal.info({
      icon: null,
      title: t('interview-information'),
      closable: true,
      width: 600,
      content: (
        <ConductInterviewResult
          form={form}
          candidate={candidate}
          interviewQuestions={interviewResult.questions}
          totalScore={interviewResult.score}
        />
      ),
    });
  };
};
