import React from 'react';
import { FileFilled, MoreOutlined } from '@ant-design/icons';
import { useDeleteCandidate, useGetCandidates } from '@features/vacancies/api/hooks';
import { useManageCandidate } from '@features/vacancies/components/candidates/hooks';
import { Candidate } from '@features/vacancies/components/candidates/types';
import {
  useAssignInterviewForm,
  useShowInterviewResult,
} from '@features/vacancies/components/interview/hooks';
import { Vacancy } from '@features/vacancies/types';
import { Button, Dropdown, List, MenuProps, theme } from 'antd';
import { useTranslation } from 'react-i18next';

interface CandidateListProps {
  vacancy: Vacancy;
}

export const CandidateList: React.FC<CandidateListProps> = ({ vacancy }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.candidates' });

  const { isLoading, data: candidates } = useGetCandidates(vacancy.id);

  const showInterviewResult = useShowInterviewResult();

  const openManageCandidateForm = useManageCandidate(vacancy.id);

  const removeCandidate = useDeleteCandidate();

  const assignInterview = useAssignInterviewForm();

  const { token } = theme.useToken();

  const renderDropdownActions = (candidate: Candidate): MenuProps => ({
    items: [
      {
        key: '1',
        label: t('assign-interview'),
        onClick: () => assignInterview(vacancy.id, candidate),
      },
      {
        key: '2',
        label: t('show-results'),
        children: candidate.interviewResults.map((item, index) => ({
          key: `2-${index}`,
          label: t('show-result-for', { stage: index + 1 }),
          onClick: () => showInterviewResult(candidate, item),
        })),
      },
      {
        key: '3',
        label: t('edit'),
        onClick: () => openManageCandidateForm('edit', candidate),
      },
      {
        key: '4',
        label: t('delete'),
        onClick: () =>
          removeCandidate.mutate({
            vacancyId: vacancy.id,
            candidateId: candidate.id,
          }),
      },
    ],
  });

  return (
    <List
      itemLayout="horizontal"
      dataSource={candidates}
      loading={isLoading}
      header={
        <Button onClick={() => openManageCandidateForm('create')}>{t('add-candidate')}</Button>
      }
      renderItem={(item, index) => (
        <List.Item
          key={index}
          actions={[
            <Dropdown key={1} placement={'bottom'} menu={renderDropdownActions(item)}>
              <Button size={'small'} icon={<MoreOutlined />} />
            </Dropdown>,
          ]}
        >
          <List.Item.Meta
            avatar={<FileFilled style={{ color: token.colorPrimary }} />}
            title={item.name}
            // description={
            // <Progress
            //   percent={item.interviewResults
            //     .map((it) => it.score)
            //     .reduce((acc, cur) => acc + cur)}
            // />
            //}
          />
        </List.Item>
      )}
    />
  );
};
