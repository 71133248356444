import { PaginatedResponse } from '@common/api/output';
import { TEMPLATES_QUERY_KEY } from '@constants/index';
import { deleteTemplate } from '@features/templates/api';
import { TemplateOutput } from '@features/templates/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';
import { App } from 'antd';

export function useDeleteTemplate() {
  const queryClient = useQueryClient();
  const { modal } = App.useApp();

  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id isn`t found'),
  );

  const mutation = useMutation<unknown, unknown, string, unknown>(
    (id) => deleteTemplate(spaceId, id),
    {
      onSuccess: (_data, id) => {
        queryClient.setQueryData<InfiniteData<PaginatedResponse<TemplateOutput>>>(
          [TEMPLATES_QUERY_KEY],
          (data) =>
            data
              ? {
                  pages: data.pages.map((page) => ({
                    metadata: {
                      ...page.metadata,
                      totalCount: -page.metadata.totalCount,
                      count: -page.metadata.count,
                    },
                    data: page.data.filter((template) => template.id !== id) ?? [],
                  })),
                  pageParams: data.pageParams,
                }
              : data,
        );
      },
    },
  );

  return (templateId: string) => {
    modal.confirm({
      title: 'Do you want to delete this template?',
      onOk: () => mutation.mutate(templateId),
    });
  };
}
