import * as React from 'react';
import logo from '@assets/logo.svg';
import { useCreateSpace } from '@features/auth/api/hooks';
import { CreateSpaceInput } from '@features/auth/api/input';
import { useAppStore } from '@store/index';
import { Avatar, Button, Form, Input, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

export const CompleteRegistration: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const [form] = Form.useForm<CreateSpaceInput>();
  const activeSpace = useAppStore((state) => state.activeSpace);
  const createSpace = useCreateSpace();

  const onFinish = async (values: CreateSpaceInput) => {
    createSpace.mutate(values);
  };

  return activeSpace ? (
    <Spin />
  ) : (
    <>
      <Avatar src={logo} style={{ display: 'block', margin: '0 auto' }} />
      <Typography.Title level={5} style={{ textAlign: 'center' }}>
        {t('space-complete-registration')}
      </Typography.Title>

      <Form
        name="normal_login"
        style={{
          marginTop: '36px',
          minWidth: '400px',
        }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item name="id" hidden={true} initialValue={v4().toString()} />
        <Form.Item
          name="name"
          label={t('space')}
          rules={[
            {
              required: true,
              message: t('space-is-required'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={createSpace.isLoading}
          >
            {t('space-continue')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
