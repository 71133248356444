import React, { useEffect, useState } from 'react';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { CollapsibleCard } from '@common/components/card';
import { Content } from '@common/types';
import { TagSelector } from '@features/tags/components/selector';
import { TemplateSelector } from '@features/templates/components/selector';
import { TemplateType } from '@features/templates/types';
import { VacancyQuestion } from '@features/vacancies/types';
import { useQuestionGenerator } from '@openai/hooks';
import { Button, DatePicker, Divider, Form, FormInstance, Input, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

export interface VacancyFormType {
  id: string;
  title: string;
  description?: Content | null | undefined;
  deadlineAt: Dayjs;
  skillSet: string[];
  questions: VacancyQuestion[];
  template: string;
}

export interface VacancyFormProps {
  form: FormInstance<VacancyFormType>;
  onFinish: (values: VacancyFormType) => void;
}

export const VacancyForm: React.FC<VacancyFormProps> = ({ form, onFinish }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.form' });
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType | null>(null);
  const questionGenerator = useQuestionGenerator();

  useEffect(() => {
    if (questionGenerator.questions.length > 0) {
      const previousQuestions = form.getFieldValue('questions') ?? [];

      form.setFieldsValue({
        questions: questionGenerator.questions.concat(previousQuestions),
      });
    }
  }, [form, questionGenerator.questions]);

  const generateQuestions = async () => {
    const title = form.getFieldValue('title');
    const description = form.getFieldValue(['description', 'content']);

    await questionGenerator.generate(title, description);
  };

  const onTemplateCreated = (template: TemplateType) => {
    form.setFieldsValue({
      template: template.id,
      questions: template.questions,
    });
    setSelectedTemplate(template);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={false}>
      <Form.Item name={'id'} initialValue={v4().toString()} hidden={true} />

      <Form.Item
        name="title"
        label={t('title')}
        rules={[{ required: true, message: t('title-is-required') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name={['description', 'content']} label={t('description')}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item hidden={true} name={['description', 'fileIds']} />

      <Form.Item
        name="deadlineAt"
        label={t('deadline')}
        rules={[{ required: true, message: t('deadline-is-required') }]}
      >
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>

      <Divider />

      <Form.Item name="skillSet" label={t('skillSet')}>
        <TagSelector
          value={form.getFieldValue('skillSet')}
          onChange={(value) => {
            form.setFieldValue('skillSet', value);
          }}
        />
      </Form.Item>

      <Form.Item name="template" label={t('template')}>
        <TemplateSelector
          value={selectedTemplate?.id ?? form.getFieldValue('template')}
          onTemplateCreated={onTemplateCreated}
          onChange={(template) => {
            template && setSelectedTemplate(template);
            template &&
              form.setFieldsValue({
                questions: template.questions,
              });
          }}
        />
      </Form.Item>

      {selectedTemplate && (
        <>
          <Button
            icon={<EditOutlined />}
            loading={questionGenerator.isLoading}
            onClick={generateQuestions}
          >
            {t('generate-questions')}
          </Button>

          <Typography.Title level={5}>{t('question-list')}</Typography.Title>
          <Form.List
            name={'questions'}
            rules={[
              {
                validator: async (_, questions) => {
                  if (!questions || questions.length < 1) {
                    return Promise.reject(new Error(t('question-min-required')));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                {fields.map((field, index) => (
                  <CollapsibleCard
                    key={index}
                    title={`${field.name + 1}. ${t('question')}`}
                    size={'small'}
                    extra={<CloseOutlined onClick={() => remove(field.name)} />}
                  >
                    <Form.Item
                      name={[field.name, 'id']}
                      initialValue={v4().toString()}
                      hidden={true}
                    />

                    <Form.Item
                      name={[field.name, 'question', 'content']}
                      label={t('question')}
                      rules={[{ required: true, message: t('question-is-required') }]}
                    >
                      <Input.TextArea autoSize />
                    </Form.Item>

                    <Form.Item name={[field.name, 'answer', 'content']} label={t('answer')}>
                      <Input.TextArea autoSize />
                    </Form.Item>

                    <Form.Item name={[field.name, 'tips', 'content']} label={t('tips')}>
                      <Input.TextArea autoSize />
                    </Form.Item>
                  </CollapsibleCard>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('question-add-question')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </div>
            )}
          </Form.List>
        </>
      )}
    </Form>
  );
};
