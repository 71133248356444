import { useEffect } from 'react';
import { USER_QUERY_KEY } from '@constants/index';
import { loadCurrentUser } from '@features/auth/api';
import { User } from '@features/auth/domain';
import { useAppStore } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

export function useGetUser(): User | null {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [user, setUser] = useAppStore((state) => [state.user, state.setUser]);

  const { isError, data } = useQuery([USER_QUERY_KEY], async () => loadCurrentUser(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: user,
    enabled: isAuthenticated(),
  });

  useEffect(() => {
    if (isError) {
      navigate('/auth');
      setUser(undefined);
    }

    if (data) {
      setUser(data);
    }
  }, [data, isError, setUser, navigate]);

  return user ?? null;
}
