import React from 'react';
import { useFinishInterview } from '@features/vacancies/api/hooks';
import { Candidate } from '@features/vacancies/components/candidates/types';
import { ConductInterviewResult } from '@features/vacancies/components/interview/form';
import { ConductInterviewResultForm } from '@features/vacancies/components/interview/types';
import { InterviewQuestionResult, InterviewResult } from '@features/vacancies/types';
import { useAppStore } from '@store/index';
import { App, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useFinishInterviewForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.interview.hooks' });
  const { modal } = App.useApp();

  const finishInterview = useFinishInterview();

  const reset = useAppStore((state) => state.resetConductInterview);

  const [form] = Form.useForm<ConductInterviewResultForm>();

  const navigate = useNavigate();

  return (
    vacancyId: string,
    interviewId: string,
    candidate: Candidate,
    interviewQuestions: InterviewQuestionResult[],
    totalScore: number,
  ) => {
    const onFinish = (values: ConductInterviewResultForm) => {
      const interviewResult: InterviewResult = {
        feedback: values.feedback,
        questions: interviewQuestions,
        score: totalScore,
      };

      finishInterview.mutate({
        vacancyId: vacancyId,
        interviewId: interviewId,
        candidateId: candidate.id,
        input: interviewResult,
      });

      reset();
      navigate(-1);
    };

    modal.confirm({
      icon: null,
      title: t('interview-information'),
      closable: true,
      width: 600,
      cancelText: t('cancel'),
      okText: t('save-interview'),
      content: (
        <ConductInterviewResult
          form={form}
          onFinish={onFinish}
          candidate={candidate}
          interviewQuestions={interviewQuestions}
          totalScore={totalScore}
        />
      ),
      onOk: () => form.submit(),
    });
  };
};
