import { useAppStore } from '@store/index';
import { Avatar, Dropdown, Space } from 'antd';
import { useSignOut } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';

export const ProfileSection = () => {
  const user = useAppStore((state) => state.user);
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.profile' });
  const signOut = useSignOut();

  return (
    <Space>
      <Dropdown
        placement={'bottom'}
        menu={{
          items: [
            {
              key: '1',
              label: t('settings'),
              disabled: true,
            },
            {
              key: '2',
              label: t('sign-out'),
              danger: true,
              onClick: () => signOut(),
            },
          ],
        }}
      >
        <Avatar style={{ minWidth: '36px' }}>{user?.email.charAt(0).toLocaleUpperCase()}</Avatar>
      </Dropdown>
    </Space>
  );
};
