import { HotVacancies } from '@features/home/components/hot-vacancies';
import { ScheduledInterviews } from '@features/home/components/scheduled-interviews';
import { Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  return (
    <Col style={{ maxWidth: '1000px' }}>
      <Typography.Title level={3}>{t('title')}</Typography.Title>
      <HotVacancies />
      <ScheduledInterviews />
    </Col>
  );
};
