import { PaginatedResponse } from '@common/api/output';
import { TEMPLATES_QUERY_KEY } from '@constants/index';
import { updateTemplate } from '@features/templates/api';
import { TemplateInput } from '@features/templates/api/input';
import { TemplateOutput } from '@features/templates/api/output';
import { RatingScaleOptionsFactory } from '@features/templates/domain';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useUpdateTemplate() {
  const queryClient = useQueryClient();
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id isn`t found'),
  );

  const factory = new RatingScaleOptionsFactory();

  return useMutation<unknown, unknown, { id: string; data: TemplateInput }, unknown>(
    ({ id, data }) => updateTemplate(spaceId, id, data),
    {
      onSuccess: (_data, { id, data }) => {
        queryClient.setQueryData<InfiniteData<PaginatedResponse<TemplateOutput>>>(
          [TEMPLATES_QUERY_KEY],
          (infiniteData) =>
            infiniteData
              ? {
                  pages: infiniteData.pages.map((page) => ({
                    ...page,
                    data: page.data.map((template) =>
                      template.id === id
                        ? {
                            ...template,
                            ...data,
                            ratingScale: {
                              type: data.ratingScale,
                              options: factory.create(data.ratingScale),
                            },
                          }
                        : template,
                    ),
                  })),
                  pageParams: infiniteData.pageParams,
                }
              : infiniteData,
        );
      },
    },
  );
}
