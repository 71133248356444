import { PaginatedResponse } from '@common/api/output';
import { TEMPLATES_QUERY_KEY } from '@constants/index';
import { createTemplate } from '@features/templates/api';
import { TemplateInput } from '@features/templates/api/input';
import { TemplateOutput } from '@features/templates/api/output';
import { RatingScaleOptionsFactory } from '@features/templates/domain';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useCreateTemplate() {
  const queryClient = useQueryClient();
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id isn`t found'),
  );

  const factory = new RatingScaleOptionsFactory();

  return useMutation<unknown, unknown, TemplateInput, unknown>(
    (data: TemplateInput) => createTemplate(spaceId, data),
    {
      onSuccess: (_data, variables) => {
        const template: TemplateOutput = {
          ...variables,
          isFavorite: false,
          ratingScale: {
            type: variables.ratingScale,
            options: factory.create(variables.ratingScale),
          },
          createdAt: new Date().toISOString(),
        };

        queryClient.setQueryData<InfiniteData<PaginatedResponse<TemplateOutput>>>(
          [TEMPLATES_QUERY_KEY],
          (data) =>
            data
              ? {
                  pages: data.pages.map((page) => ({
                    metadata: {
                      ...page.metadata,
                      totalCount: +page.metadata.totalCount,
                      count: +page.metadata.count,
                    },
                    data: [...page.data, template],
                  })),
                  pageParams: data.pageParams,
                }
              : data,
        );
      },
    },
  );
}
