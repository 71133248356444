import React from 'react';
import { CardInterview } from '@features/schedule/components/card';
import { useGetInterviews } from '@features/vacancies/api/hooks';
import { DatePicker, List } from 'antd';

export interface ScheduledInterviewsTabProps {
  vacancyId: string;
}

export const ScheduledInterviewsTab: React.FC<ScheduledInterviewsTabProps> = ({ vacancyId }) => {
  const { isLoading, data: interviews } = useGetInterviews(vacancyId);

  return (
    <>
      <DatePicker style={{ width: '100%' }} />
      <List
        itemLayout={'horizontal'}
        dataSource={interviews}
        size={'small'}
        loading={isLoading}
        renderItem={(item, index) => <CardInterview key={index} item={item} />}
      />
    </>
  );
};
