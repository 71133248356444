import * as React from 'react';
import { Layout, Space } from 'antd';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const pageLayoutStyles: React.CSSProperties = {
  height: '100vh',
  justifyContent: 'center',
  alignContent: 'center',
};
const contentStyles: React.CSSProperties = { margin: '0 auto', flex: '0 auto' };

const spaceStyles: React.CSSProperties = {
  width: '100%',
  boxShadow: '5px 8px 24px 5px rgba(0, 0, 0, 0.1)',
  borderRadius: '24px',
  padding: '24px',
  background: 'white',
};

export const AuthLayout: React.FC = () => (
  <Layout style={pageLayoutStyles}>
    <Content style={contentStyles}>
      <Space direction="vertical" align="center" style={spaceStyles}>
        <Outlet />
      </Space>
    </Content>
  </Layout>
);
