import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useGetTemplates } from '@features/templates/api/hooks';
import { useManageTemplate } from '@features/templates/hooks';
import { TemplateType } from '@features/templates/types';
import { Button, Divider, Select } from 'antd';
import { useTranslation } from 'react-i18next';

export interface TemplateSelectorProps {
  value: string | undefined | null;
  onChange: (template: TemplateType | undefined) => void;
  onTemplateCreated: (template: TemplateType) => void;
}

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  value,
  onChange,
  onTemplateCreated,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.form' });

  const openManageTemplateForm = useManageTemplate();

  const { data } = useGetTemplates();

  return (
    <Select
      value={value}
      options={data.map((template) => ({
        value: template.id,
        label: template.title,
      }))}
      onChange={(value) => {
        const template = data.find((template) => template.id === value);
        onChange(template);
      }}
      filterOption={false}
      dropdownRender={(menu) => (
        <>
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => {
              openManageTemplateForm('modal', 'create', undefined, onTemplateCreated);
            }}
          >
            {t('template-create-new')}
          </Button>
          <Divider style={{ margin: '8px 0' }} />
          {menu}
        </>
      )}
    />
  );
};
