import { PaginatedResponse } from '@common/api/output';
import { INTERVIEWS_QUERY_KEY, VACANCIES_QUERY_KEY } from '@constants/index';
import { assignVacancyInterview } from '@features/vacancies/api';
import { AddVacancyInterviewInput } from '@features/vacancies/api/input';
import { InterviewOutput, VacancyOutput } from '@features/vacancies/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useAssignInterview() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { vacancyId: string; data: AddVacancyInterviewInput },
    unknown
  >(({ vacancyId, data }) => assignVacancyInterview(spaceId, vacancyId, data), {
    onSuccess: (_data, { vacancyId, data }) => {
      const updateVacancy = (vacancy: VacancyOutput): VacancyOutput => ({
        ...vacancy,
        totalInterviews: +vacancy.totalInterviews,
      });

      queryClient.invalidateQueries({ queryKey: [INTERVIEWS_QUERY_KEY] });

      queryClient.setQueryData<InfiniteData<PaginatedResponse<InterviewOutput>>>(
        [VACANCIES_QUERY_KEY, vacancyId, INTERVIEWS_QUERY_KEY],
        (infiniteData) =>
          infiniteData
            ? {
                pages: infiniteData.pages.map((page) => ({
                  ...page,
                  data: [...page.data, { ...data, vacancyId: vacancyId }],
                })),
                pageParams: infiniteData.pageParams,
              }
            : infiniteData,
      );

      queryClient.setQueryData<InfiniteData<PaginatedResponse<VacancyOutput>>>(
        [VACANCIES_QUERY_KEY],
        (infiniteData) =>
          infiniteData
            ? {
                pages: infiniteData.pages.map((page) => ({
                  ...page,
                  data: page.data.map((vacancy) =>
                    vacancy.id === vacancyId ? updateVacancy(vacancy) : vacancy,
                  ),
                })),
                pageParams: infiniteData.pageParams,
              }
            : infiniteData,
      );

      queryClient.setQueryData<VacancyOutput>([VACANCIES_QUERY_KEY, vacancyId], (vacancy) =>
        vacancy ? updateVacancy(vacancy) : vacancy,
      );
    },
  });
}
