import { ENTITY_TAGS_QUERY_KEY } from '@constants/index';
import { deleteEntityTagAssignment } from '@features/tags/api';
import { useAppStore } from '@store/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useDeleteEntityTag() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { entityId: string; entityType: string; tagId: string },
    unknown
  >(
    ({ tagId, entityId, entityType }) =>
      deleteEntityTagAssignment(spaceId, entityType, entityId, tagId),
    {
      onSuccess: (_data, { entityId, entityType }) => {
        queryClient.invalidateQueries({ queryKey: [ENTITY_TAGS_QUERY_KEY, entityType, entityId] });
      },
    },
  );
}
