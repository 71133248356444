import React from 'react';
import { Dashboard } from '@features/dashboard';
import { Home } from '@features/home';
import { Profile } from '@features/profile';
import { Schedule } from '@features/schedule';
import { Templates } from '@features/templates';
import { Vacancies } from '@features/vacancies';
import { VacancyDetail } from '@features/vacancies/components/detail';
import { ConductInterview } from '@features/vacancies/components/interview';
import { PrivateRoute } from '@routes/private-route';
import { RouteObject } from 'react-router-dom';

export const dashboardRoutes: RouteObject[] = [
  {
    path: ':spaceId?',
    element: (
      <PrivateRoute loginPath={'/auth/sign-in'}>
        <Dashboard />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'home?',
        index: true,
        element: <Home />,
      },
      {
        path: 'vacancies',
        element: <Vacancies />,
      },
      {
        path: 'vacancies/:vacancyId',
        element: <VacancyDetail />,
      },
      {
        path: 'vacancies/:vacancyId/interviews/:interviewId',
        element: <ConductInterview />,
      },
      {
        path: 'schedule',
        element: <Schedule />,
      },
      {
        path: 'templates',
        element: <Templates />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
];
