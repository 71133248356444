import { instance } from '@api/index';
import { CreateSpaceInput, SignInInput, SignUpInput } from '@features/auth/api/input';
import { AuthTokenOutput, SpaceOutput, UserOutput } from '@features/auth/api/output';
import { createRefresh } from 'react-auth-kit';
import { v4 as uuid } from 'uuid';

export async function signIn(input: SignInInput): Promise<AuthTokenOutput> {
  const { data } = await instance.post<AuthTokenOutput>('jwt/create', {
    email: input.email,
    password: input.password,
  });

  return data;
}

export async function signUp(input: SignUpInput): Promise<never> {
  const { data } = await instance.post<never>('users', {
    email: input.email,
    password: input.password,
    uuid: uuid().toString(),
  });

  return data;
}

export async function refreshJwtToken(refresh: string | undefined): Promise<AuthTokenOutput> {
  const { data } = await instance.post<AuthTokenOutput>('jwt/refresh', {
    refreshToken: refresh,
  });

  return data;
}

export async function loadCurrentUser(): Promise<UserOutput> {
  const { data } = await instance.get<UserOutput>('users/me');

  return data;
}

export async function loadSpaces(): Promise<SpaceOutput[]> {
  const { data } = await instance.get<SpaceOutput[]>('spaces');

  return data;
}

export async function createSpace(input: CreateSpaceInput): Promise<never> {
  const { data } = await instance.post<never>('spaces', input);

  return data;
}

export const refreshApi = createRefresh({
  interval: 5,
  refreshApiCallback: async ({ refreshToken }) => {
    try {
      const response = await refreshJwtToken(refreshToken);
      return {
        isSuccess: true,
        newAuthToken: response.accessToken,
        newRefreshToken: response.refreshToken,
        newAuthTokenExpireIn: 10,
        newRefreshTokenExpiresIn: 1440,
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
        newAuthToken: '',
      };
    }
  },
});
