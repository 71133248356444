import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Form, FormInstance, Input, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

export interface CandidateFormInstance {
  id: string;
  name: string;
  email?: string;
  resume?: string;
}

interface CandidateFormProps {
  form: FormInstance<CandidateFormInstance>;
  onFinish: (values: CandidateFormInstance) => void;
}

export const CandidateForm: React.FC<CandidateFormProps> = ({ form, onFinish }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.candidates' });

  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Form.Item name={'id'} hidden={true} initialValue={v4().toString()} />
      <Form.Item label={t('name')} name={'name'}>
        <Input />
      </Form.Item>
      <Form.Item label={t('email')} name={'email'}>
        <Input />
      </Form.Item>
      <Form.Item label={t('resume')} name={'resume'} initialValue={null}>
        <Upload.Dragger action="/upload.do" listType="picture-card">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('resume-upload-file-info')}</p>
        </Upload.Dragger>
      </Form.Item>
    </Form>
  );
};
