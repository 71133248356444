import { instance } from '@api/index';
import { PaginatedResponse } from '@common/api/output';
import { DEFAULT_PAGE_SIZE } from '@constants/index';
import { TemplateInput } from '@features/templates/api/input';
import { TemplateOutput } from '@features/templates/api/output';

const ENDPOINT = 'templates';

export async function loadTemplates(
  spaceId: string,
  page: number,
): Promise<PaginatedResponse<TemplateOutput>> {
  const offset = page * DEFAULT_PAGE_SIZE;

  const { data } = await instance.get<PaginatedResponse<TemplateOutput>>(`${spaceId}/${ENDPOINT}`, {
    params: {
      limit: DEFAULT_PAGE_SIZE,
      offset: offset,
    },
  });

  return data;
}

export async function createTemplate(spaceId: string, input: TemplateInput): Promise<void> {
  const { data } = await instance.post(`${spaceId}/${ENDPOINT}`, input);

  return data;
}

export async function loadTemplate(spaceId: string, id: string): Promise<TemplateOutput> {
  const { data } = await instance.get<TemplateOutput>(`${spaceId}/${ENDPOINT}/${id}`);

  return data;
}

export async function updateTemplate(
  spaceId: string,
  id: string,
  input: TemplateInput,
): Promise<void> {
  const { data } = await instance.put(`${spaceId}/${ENDPOINT}/${id}`, input);

  return data;
}

export async function setFavouriteTemplate(spaceId: string, id: string): Promise<void> {
  const { data } = await instance.put(`${spaceId}/${ENDPOINT}/${id}/favourite`);

  return data;
}

export async function deleteTemplate(spaceId: string, id: string): Promise<void> {
  const { data } = await instance.delete(`${spaceId}/${ENDPOINT}/${id}`);

  return data;
}
