import React, { CSSProperties, useState } from 'react';
import { UpOutlined } from '@ant-design/icons';
import { Card, CardProps, theme } from 'antd';

export interface CollapsibleCardProps {
  isExpanded?: boolean;
  titleStyle?: CSSProperties;
}

export const CollapsibleCard: React.FC<CollapsibleCardProps & CardProps> = (props) => {
  const { token } = theme.useToken();
  const [isExpanded, setIsExpanded] = useState(props.isExpanded ?? true);

  return (
    <Card
      {...props}
      bodyStyle={{
        maxHeight: isExpanded ? `100%` : 0,
        padding: isExpanded ? token.padding : '0 18px',
        transition: 'max-height 0.3s ease-out, padding 0.3s ease-out',
        overflow: 'hidden',
      }}
      title={
        <>
          <UpOutlined
            style={{
              marginRight: 8,
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(90deg)',
              transition: 'transform 0.3s',
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          {props.title}
        </>
      }
    >
      {isExpanded ? props.children : <></>}
    </Card>
  );
};
