export type ContentType = {
  content: string;
  fileIds?: string[] | undefined;
};

export type QuestionType = {
  id: string;
  question: ContentType;
  answer?: ContentType | undefined | null;
  tips?: ContentType | undefined | null;
};

export type RatingScaleOptionType = {
  scale: number;
  value: string;
};

export type RatingScaleType = {
  type: RatingScaleEnum | string;
  options: RatingScaleOptionType[];
};

export type TemplateType = {
  id: string;
  title: string;
  description?: ContentType | undefined | null;
  tags?: string[];
  createdAt?: string;
  questions: QuestionType[];
  isFavorite?: boolean;
  ratingScale: RatingScaleType;
};

export type ViewType = 'card' | 'table';

export type TemplateFormActionType = 'create' | 'edit';

export enum RatingScaleEnum {
  PERCENT = 'PERCENT',
  LEVEL = 'LEVEL',
  POINTS = 'POINTS',
  // CUSTOM = 'Custom',
}
