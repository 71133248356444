import React from 'react';
import { LeftOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { TagContainer } from '@features/tags/components/tag-container';
import { useDeleteVacancy, useGetVacancy } from '@features/vacancies/api/hooks';
import { CandidateList } from '@features/vacancies/components/candidates';
import { ScheduledInterviewsTab } from '@features/vacancies/components/detail/tabs';
import { useManageVacancy } from '@features/vacancies/hooks';
import { Vacancy } from '@features/vacancies/types';
import { throwException } from '@utils/index';
import type { DescriptionsProps, MenuProps, TabsProps } from 'antd';
import { App, Button, Col, Collapse, Descriptions, Dropdown, Row, Spin, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const VacancyDetail: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.detail' });
  const { vacancyId } = useParams();
  const navigate = useNavigate();

  const { modal } = App.useApp();

  const openManageVacancyForm = useManageVacancy();

  const deleteVacancy = useDeleteVacancy();

  const { isLoading, data: vacancy } = useGetVacancy(
    vacancyId ?? throwException('Vacancy Id is not defined'),
  );

  const renderDescriptionItems = (vacancy: Vacancy): DescriptionsProps['items'] => [
    {
      key: '1',
      label: t('deadline'),
      span: 3,
      children: dayjs(vacancy.deadlineAt).format('DD/MM/YYYY'),
    },
    {
      key: '2',
      label: t('skillSet'),
      span: 3,
      children: <TagContainer entityType={'vacancy'} entityId={vacancyId ?? ''} />,
    },
    {
      key: '3',
      label: t('description'),
      span: 3,
      children: vacancy.description?.content,
    },
  ];

  const renderTabItems = (vacancy: Vacancy): TabsProps['items'] => [
    {
      key: '1',
      label: t('candidates'),
      children: <CandidateList vacancy={vacancy} />,
    },
    {
      key: '2',
      label: t('interviews'),
      children: <ScheduledInterviewsTab vacancyId={vacancy.id} />,
    },
    {
      key: '3',
      label: t('questions'),
      children: (
        <Collapse
          items={vacancy.questions.map((item, index) => ({
            key: index,
            label: `${index + 1}. ${item.question.content}`,
            children: item.answer?.content,
          }))}
          ghost
        />
      ),
    },
  ];

  const renderMenuItems = (vacancy: Vacancy): MenuProps => ({
    items: [
      {
        key: '1',
        label: t('edit'),
        onClick: () => openManageVacancyForm('edit', vacancy),
      },
      {
        key: '2',
        label: t('delete'),
        onClick: () =>
          modal.confirm({
            title: 'Do you want to delete this template?',
            onOk: () => {
              deleteVacancy.mutate(vacancy.id);
              navigate(-1);
            },
          }),
      },
    ],
  });

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
          <Button
            style={{ paddingLeft: 0 }}
            type={'link'}
            icon={<LeftOutlined />}
            onClick={() => navigate(-1)}
          >
            {t('go-back')}
          </Button>
        </Col>
        {isLoading && (
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin indicator={<LoadingOutlined />} />
          </Col>
        )}
        {vacancy && (
          <>
            <Col lg={24} xl={12}>
              <Descriptions
                title={vacancy.title}
                extra={
                  <Dropdown placement={'bottom'} menu={renderMenuItems(vacancy)}>
                    <Button size={'small'} icon={<MoreOutlined />} />
                  </Dropdown>
                }
                layout={'vertical'}
                items={renderDescriptionItems(vacancy)}
              />
            </Col>
            <Col lg={24} xl={12}>
              <Tabs items={renderTabItems(vacancy)} />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
