import { PaginatedResponse } from '@common/api/output';
import { CANDIDATES_QUERY_KEY, VACANCIES_QUERY_KEY } from '@constants/index';
import { updateVacancyCandidate } from '@features/vacancies/api';
import { UpdateVacancyCandidateInput } from '@features/vacancies/api/input';
import { CandidateOutput } from '@features/vacancies/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useUpdateCandidate() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    {
      vacancyId: string;
      candidateId: string;
      input: UpdateVacancyCandidateInput;
    },
    unknown
  >(
    ({ vacancyId, candidateId, input }) =>
      updateVacancyCandidate(spaceId, vacancyId, candidateId, input),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries({ queryKey: [CANDIDATES_QUERY_KEY] });

        queryClient.setQueryData<InfiniteData<PaginatedResponse<CandidateOutput>>>(
          [VACANCIES_QUERY_KEY, variables.vacancyId, CANDIDATES_QUERY_KEY],
          (infiniteData) =>
            infiniteData
              ? {
                  pages: infiniteData.pages.map((page) => ({
                    ...page,
                    data: page.data.filter((candidate) =>
                      candidate.id === variables.candidateId
                        ? { ...candidate, ...variables.input }
                        : candidate,
                    ),
                  })),
                  pageParams: infiniteData.pageParams,
                }
              : infiniteData,
        );
      },
    },
  );
}
