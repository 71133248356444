import React from 'react';
import { authRoutes } from '@features/auth/routes';
import { dashboardRoutes } from '@features/dashboard/routes';
import { createBrowserRouter } from 'react-router-dom';

import App from '../App';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [...authRoutes, ...dashboardRoutes],
  },
]);
