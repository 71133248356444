import { TAGS_QUERY_KEY } from '@constants/index';
import { createTag } from '@features/tags/api';
import { TagInput } from '@features/tags/api/input';
import { useAppStore } from '@store/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useCreateTag() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, TagInput, unknown>((data) => createTag(spaceId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TAGS_QUERY_KEY] });
    },
  });
}
