import React from 'react';
import { useAssignInterview } from '@features/vacancies/api/hooks';
import { AssignInterviewType, Candidate } from '@features/vacancies/components/candidates/types';
import { App, DatePicker, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

export const useAssignInterviewForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.interview.hooks' });
  const { modal } = App.useApp();
  const addInterview = useAssignInterview();

  const [form] = Form.useForm<AssignInterviewType>();

  return (vacancyId: string, candidate: Candidate) => {
    const onFinish = (values: AssignInterviewType) => {
      console.log(values);
      addInterview.mutate({
        vacancyId: vacancyId,
        data: {
          id: values.id,
          startAt: values.dateRange[0].toISOString(),
          endAt: values.dateRange[1].toISOString(),
          meetLink: values.meetUrl,
          candidateId: candidate.id,
        },
      });
    };

    modal.info({
      closable: true,
      title: t('assign-interview'),
      icon: null,
      content: (
        <Form form={form} layout={'vertical'} onFinish={onFinish}>
          <Form.Item name={'id'} hidden={true} initialValue={v4().toString()} />

          <Form.Item name={'meetUrl'} label={t('meet-link')}>
            <Input />
          </Form.Item>
          <Form.Item name={'dateRange'} label={t('date-interview')}>
            <DatePicker.RangePicker
              format={'YYYY-MM-DD HH:mm'}
              showTime={{ format: 'HH:mm', minuteStep: 5 }}
            />
          </Form.Item>
        </Form>
      ),
      okText: t('save'),
      onOk: () => form.submit(),
    });
  };
};
