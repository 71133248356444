import { InterviewQuestionResult } from '@features/vacancies/types';
import { AppStore } from '@store/types';
import { StateCreator } from 'zustand';

export interface ConductInterviewSlice {
  currentQuestion: InterviewQuestionResult | undefined;
  interviewQuestions: InterviewQuestionResult[];
  setCurrentQuestion: (question: InterviewQuestionResult) => void;
  setInterviewQuestions: (questions: InterviewQuestionResult[]) => void;
  skipQuestion: () => void;
  nextQuestion: () => void;
  setQuestionRate: (rate: number) => void;
  setQuestionNotes: (notes: string) => void;
  resetConductInterview: () => void;
}

export const createConductInterviewSlice: StateCreator<AppStore, [], [], ConductInterviewSlice> = (
  set,
) => ({
  currentQuestion: undefined,
  interviewQuestions: [],
  setCurrentQuestion: (question) => set(() => ({ currentQuestion: question })),
  setInterviewQuestions: (questions) => set(() => ({ interviewQuestions: questions })),
  skipQuestion: () =>
    set((state) => ({
      currentQuestion: state.interviewQuestions.find(
        ({ index }) => state.currentQuestion && index === (state.currentQuestion.index ?? 0) + 1,
      ),
      interviewQuestions: state.interviewQuestions.map((item) => {
        if (state.currentQuestion && item.index === state.currentQuestion.index) {
          item.score = 0;
          item.skipped = true;
        }

        return item;
      }),
    })),
  nextQuestion: () =>
    set((state) => ({
      currentQuestion: state.interviewQuestions.find(
        ({ index }) => state.currentQuestion && index === (state.currentQuestion.index ?? 0) + 1,
      ),
    })),
  setQuestionRate: (rate) =>
    set((state) => ({
      interviewQuestions: state.interviewQuestions.map((item) => {
        if (state.currentQuestion && item.index === state.currentQuestion.index) {
          item.score = rate;
          item.skipped = false;
        }

        return item;
      }),
    })),
  setQuestionNotes: (notes) =>
    set((state) => ({
      interviewQuestions: state.interviewQuestions.map((item) => {
        if (state.currentQuestion && item.index === state.currentQuestion.index) {
          item.notes = notes;
        }

        return item;
      }),
    })),
  resetConductInterview: () => set(() => ({ currentQuestion: undefined, interviewQuestions: [] })),
});
