import React from 'react';
import { Interview } from '@features/schedule/types';
import { useGetCandidate } from '@features/vacancies/api/hooks/use-get-candidate';
import { Descriptions } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export interface InterviewDetailProps {
  item: Interview;
}

export const InterviewDetail: React.FC<InterviewDetailProps> = ({ item }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'schedule.detail' });

  const { data } = useGetCandidate(item.candidateId);

  return (
    <Descriptions
      layout={'horizontal'}
      items={[
        {
          key: 1,
          span: 3,
          label: t('candidate'),
          children: data && data.name,
        },
        {
          key: 2,
          span: 3,
          label: t('start-at'),
          children: dayjs(item.startAt).format('DD/MM/YYYY HH:mm'),
        },
        {
          key: 3,
          span: 3,
          label: t('end-at'),
          children: dayjs(item.endAt).format('DD/MM/YYYY HH:mm'),
        },
        {
          key: 4,
          span: 3,
          label: t('meet-link'),
          children: (
            <a href={item.meetLink} target={'_blank'} rel="noreferrer">
              {item.meetLink}
            </a>
          ),
        },
      ]}
    />
  );
};
