import React from 'react';
import { FireFilled } from '@ant-design/icons';
import { useGetVacancies } from '@features/vacancies/api/hooks';
import { VacancyList } from '@features/vacancies/components/list';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const HotVacancies: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  const { isLoading, data, hasNextPage, fetchNextPage } = useGetVacancies(2);

  return (
    <>
      <Typography.Title level={4}>
        <FireFilled style={{ color: 'orange' }} /> {t('hot-vacancies')}
      </Typography.Title>

      <VacancyList
        gutter={16}
        span={12}
        isLoading={isLoading}
        vacancies={data}
        hasMore={hasNextPage}
        loadVacancies={() => fetchNextPage()}
      />
    </>
  );
};
