import React, { useMemo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { TemplateCard } from '@features/templates/components/card';
import { TemplateType } from '@features/templates/types';
import { Col, Empty, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

const rootStyles: React.CSSProperties = {
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  overflowY: 'auto',
};

interface TemplateListProps {
  isLoading: boolean;
  hasMore: boolean;
  templates: TemplateType[];
  setIsFavorite: (templateId: string) => void;
  editTemplate: (template: TemplateType) => void;
  deleteTemplate: (templateId: string) => void;
  loadTemplates: (page: number) => void;
}

export const TemplateList: React.FC<TemplateListProps> = ({
  isLoading,
  hasMore,
  templates,
  setIsFavorite,
  deleteTemplate,
  editTemplate,
  loadTemplates,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'templates.list' });
  const isEmpty = useMemo(() => !isLoading && !templates.length, [isLoading, templates.length]);

  return (
    <div style={rootStyles}>
      <InfiniteScroll
        initialLoad={true}
        loadMore={loadTemplates}
        hasMore={hasMore}
        useWindow={false}
      >
        <Row gutter={[16, 16]}>
          {templates.map((item) => (
            <Col span={8} key={`template-${item.id}`}>
              <TemplateCard
                template={item}
                setIsFavorite={setIsFavorite}
                editTemplate={editTemplate}
                deleteTemplate={deleteTemplate}
              />
            </Col>
          ))}

          {isLoading && (
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin indicator={<LoadingOutlined />} />
            </Col>
          )}

          {isEmpty && (
            <Col span={24}>
              <Empty description={t('empty')} />
            </Col>
          )}
        </Row>
      </InfiniteScroll>
    </div>
  );
};
