import { instance } from '@api/index';
import { AssignEntityInput, GetEntitiesTagsInput, TagInput } from '@features/tags/api/input';
import { TagOutput } from '@features/tags/api/output';

const ENDPOINT = 'tags';
const ENTITIES_TAGS_ENDPOINT = 'entities-tags';

export async function createTag(spaceId: string, input: TagInput): Promise<void> {
  const { data } = await instance.post(`${spaceId}/${ENDPOINT}`, input);

  return data;
}

export async function updateTag(spaceId: string, id: string, input: TagInput): Promise<void> {
  const { data } = await instance.put(`${spaceId}/${ENDPOINT}/${id}`, input);

  return data;
}

export async function deleteTag(spaceId: string, id: string): Promise<void> {
  const { data } = await instance.delete(`${spaceId}/${ENDPOINT}/${id}`);

  return data;
}

export async function loadTags(spaceId: string): Promise<TagOutput[]> {
  const { data } = await instance.get<TagOutput[]>(`${spaceId}/${ENDPOINT}`);

  return data;
}

export async function assignEntityTag(spaceId: string, input: AssignEntityInput): Promise<void> {
  const { data } = await instance.post(`${spaceId}/${ENTITIES_TAGS_ENDPOINT}`, input);

  return data;
}

export async function loadEntityTags(
  spaceId: string,
  entityType: string,
  input: GetEntitiesTagsInput,
): Promise<TagOutput[]> {
  const { data } = await instance.post<TagOutput[]>(
    `${spaceId}/${ENTITIES_TAGS_ENDPOINT}/${entityType}`,
    input,
  );

  return data;
}

export async function deleteEntityTagAssignment(
  spaceId: string,
  entityType: string,
  entityId: string,
  tagId: string,
): Promise<void> {
  const { data } = await instance.delete(
    `${spaceId}/${ENTITIES_TAGS_ENDPOINT}/${entityType}/${entityId}/${tagId}`,
  );

  return data;
}
