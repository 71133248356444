import { ThemeConfig } from 'antd';

export const config: ThemeConfig = {
  token: {
    colorPrimary: '#006ff7',
    colorInfo: '#006ff7',
    colorSuccess: '#2cc73b',
    colorWarning: '#ffcc00',
    colorError: '#f04337',
    colorTextBase: '#16161a',
    fontFamily: 'Inter',
  },
};
