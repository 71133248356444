import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Drawer } from 'antd';
import { v4 } from 'uuid';

export type DrawerProps = {
  id?: string;
  title?: ReactNode;
  content?: ReactNode;
  extra?: React.ReactNode;
  size?: 'default' | 'large';
};

type DrawerContextType = {
  id: string;
  isOpen: boolean;
  openDrawer: (props: DrawerProps) => void;
  closeDrawer: () => void;
};

const DefaultDrawerContext: DrawerContextType = {
  id: '',
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openDrawer: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeDrawer: () => {},
};

const DrawerContext = createContext<DrawerContextType>(DefaultDrawerContext);

interface DrawerProviderProps {
  children: ReactNode;
}

export const DrawerProvider: React.FC<DrawerProviderProps> = ({ children }) => {
  const [id, setId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [drawerProps, setDrawerProps] = useState<DrawerProps | undefined>(undefined);

  const openDrawer = (props: DrawerProps) => {
    setDrawerProps(props);
    setIsOpen(true);
    setId(props.id ?? v4().toString());
  };

  const closeDrawer = () => {
    setIsOpen(false);
    setDrawerProps(undefined);
  };

  return (
    <DrawerContext.Provider value={{ id, isOpen, openDrawer, closeDrawer }}>
      {drawerProps && (
        <Drawer
          title={drawerProps.title}
          open={isOpen}
          onClose={closeDrawer}
          extra={drawerProps.extra}
          size={drawerProps.size}
        >
          {drawerProps.content}
        </Drawer>
      )}
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
