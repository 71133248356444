import { createSpace } from '@features/auth/api';
import { CreateSpaceInput } from '@features/auth/api/input';
import { useAppStore } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export function useCreateSpace() {
  const navigate = useNavigate();
  const setActiveSpace = useAppStore((state) => state.setActiveSpace);

  return useMutation<never, unknown, CreateSpaceInput, unknown>(
    (data: CreateSpaceInput) => createSpace(data),
    {
      onSuccess: (_data, variables) => {
        setActiveSpace(variables);
        navigate(`/${variables.id}/home`);
      },
    },
  );
}
