import React, { useRef } from 'react';
import { BorderOutlined, PlusOutlined, TableOutlined } from '@ant-design/icons';
import {
  useDeleteTemplate,
  useGetTemplates,
  useSetFavouriteTemplate,
} from '@features/templates/api/hooks';
import { TemplateList } from '@features/templates/components/list';
import { TemplateTable } from '@features/templates/components/table';
import { useManageTemplate } from '@features/templates/hooks';
import { useAppStore } from '@store/index';
import { Button, Radio, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const Templates = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'templates' });
  const rowRef = useRef<number>(68);
  const openManageTemplateForm = useManageTemplate();
  const { isLoading, data: templates, hasNextPage, fetchNextPage, totalCount } = useGetTemplates();
  const deleteTemplate = useDeleteTemplate();
  const setIsFavorite = useSetFavouriteTemplate();
  const viewType = useAppStore((state) => state.templateViewType);
  const setViewType = useAppStore((state) => state.setTemplateViewType);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        height: `calc(100vh - ${rowRef.current}px)`,
        flexDirection: 'column',
      }}
    >
      <Row
        ref={(ref) => (rowRef.current = ref?.offsetHeight ?? rowRef.current)}
        justify={'space-between'}
        style={{ alignItems: 'baseline', marginBottom: '20px', width: '100%' }}
      >
        <Typography.Title level={3} style={{ display: 'flex' }}>
          {t('title')}
          <Button
            type={'link'}
            icon={<PlusOutlined />}
            onClick={() => openManageTemplateForm('drawer', 'create')}
          >
            {t('add-template')}
          </Button>
        </Typography.Title>

        <Radio.Group value={viewType} onChange={(e) => setViewType(e.target.value)}>
          <Radio.Button value={'card'}>
            <BorderOutlined />
          </Radio.Button>
          <Radio.Button value={'table'}>
            <TableOutlined />
          </Radio.Button>
        </Radio.Group>
      </Row>

      {viewType == 'table' ? (
        <TemplateTable
          isLoading={isLoading}
          totalCount={totalCount}
          templates={templates}
          setIsFavorite={setIsFavorite.mutate}
          editTemplate={(template) => openManageTemplateForm('drawer', 'edit', template)}
          deleteTemplate={deleteTemplate}
          loadTemplates={() => fetchNextPage()}
        />
      ) : (
        <TemplateList
          isLoading={isLoading}
          hasMore={hasNextPage ?? false}
          templates={templates}
          setIsFavorite={setIsFavorite.mutate}
          editTemplate={(template) => openManageTemplateForm('drawer', 'edit', template)}
          deleteTemplate={deleteTemplate}
          loadTemplates={() => fetchNextPage()}
        />
      )}
    </div>
  );
};
