import { PaginatedResponse } from '@common/api/output';
import { VACANCIES_QUERY_KEY } from '@constants/index';
import { deleteVacancy } from '@features/vacancies/api';
import { VacancyOutput } from '@features/vacancies/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useDeleteVacancy() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string, unknown>(
    (vacancyId) => deleteVacancy(spaceId, vacancyId),
    {
      onSuccess: (_data, vacancyId) => {
        queryClient.setQueryData<InfiniteData<PaginatedResponse<VacancyOutput>>>(
          [VACANCIES_QUERY_KEY],
          (data) =>
            data
              ? {
                  pages: data.pages.map((page) => ({
                    metadata: {
                      ...page.metadata,
                      totalCount: -page.metadata.totalCount,
                      count: -page.metadata.count,
                    },
                    data: page.data.filter((vacancy) => vacancy.id !== vacancyId) ?? [],
                  })),
                  pageParams: data.pageParams,
                }
              : data,
        );
      },
    },
  );
}
