import React, { useEffect } from 'react';
import { useAddCandidate, useUpdateCandidate } from '@features/vacancies/api/hooks';
import {
  CandidateForm,
  CandidateFormInstance,
} from '@features/vacancies/components/candidates/form';
import { Candidate } from '@features/vacancies/components/candidates/types';
import { App, Form } from 'antd';
import { useTranslation } from 'react-i18next';

export const useManageCandidate = (vacancyId: string) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.candidates' });
  const [form] = Form.useForm<CandidateFormInstance>();
  const { modal, notification } = App.useApp();

  const { mutate: addCandidate, isSuccess: createIsSuccess } = useAddCandidate();

  const { mutate: updateCandidate, isSuccess: updateIsSuccess } = useUpdateCandidate();

  const clearState = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess) {
      clearState();
      notification.success({
        message: t('message'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createIsSuccess, updateIsSuccess]);

  return (type: FormActionType, item?: Candidate, onSubmit?: (values: Candidate) => void) => {
    item && form.setFieldsValue(item);

    const title = type === 'create' ? t('modal-title-add') : t('modal-title-edit');
    const okText = type === 'create' ? t('modal-ok-text-add') : t('modal-ok-text-save');

    const onFinish = (values: CandidateFormInstance) => {
      switch (type) {
        case 'create':
          addCandidate({
            vacancyId: vacancyId,
            data: values,
          });
          break;
        case 'edit':
          item &&
            updateCandidate({
              vacancyId: vacancyId,
              candidateId: item.id,
              input: values,
            });
          break;
      }

      if (onSubmit) {
        onSubmit({
          ...values,
          interviewResults: item?.interviewResults ?? [],
        });
      }
    };

    modal.info({
      closable: true,
      title: title,
      icon: null,
      content: <CandidateForm form={form} onFinish={onFinish} />,
      okText: okText,
      onOk: () => form.submit(),
    });
  };
};

type FormActionType = 'create' | 'edit';
