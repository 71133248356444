import React from 'react';
import { CalendarOutlined, RightOutlined } from '@ant-design/icons';
import { InterviewDetail } from '@features/schedule/components/detail';
import { Interview } from '@features/schedule/types';
import { useGetCandidate } from '@features/vacancies/api/hooks/use-get-candidate';
import { useAppStore } from '@store/index';
import { throwException } from '@utils/index';
import { App, Avatar, Card, List, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface CardInterviewProps {
  item: Interview;
}

export const CardInterview: React.FC<CardInterviewProps> = ({ item }) => {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is not found'),
  );
  const { t } = useTranslation('translation', { keyPrefix: 'schedule.card' });

  const { modal } = App.useApp();
  const navigate = useNavigate();

  const { data: candidate } = useGetCandidate(item.candidateId);

  return (
    <Card
      style={{ width: '100%', margin: '16px 0' }}
      size="small"
      onClick={() => {
        modal.info({
          closable: true,
          icon: null,
          title: t('interview-title', { candidate: candidate?.name }),
          content: <InterviewDetail item={item} />,
          okText: t('start-interview'),
          onOk: () => navigate(`/${spaceId}/vacancies/${item.vacancyId}/interviews/${item.id}`),
        });
      }}
    >
      <List.Item style={{ padding: 0 }} extra={<RightOutlined />}>
        <List.Item.Meta
          style={{ alignItems: 'center' }}
          avatar={<Avatar icon={<CalendarOutlined />} />}
          title={
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              <span style={{ paddingRight: '4px' }}>
                {dayjs(item.startAt).format('DD/MM/YY HH:mm')} -{' '}
                {dayjs(item.endAt).format('DD/MM/YY HH:mm')}
              </span>
              {`Interview with ${candidate?.name}`}
            </Typography.Paragraph>
          }
          description={candidate && candidate.name}
        />
      </List.Item>
    </Card>
  );
};
