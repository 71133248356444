import React, { useEffect } from 'react';
import { useDrawer } from '@context/drawer';
import { useAssignEntityTag } from '@features/tags/api/hooks/use-assign-entity-tag';
import { useCreateVacancy, useUpdateVacancy } from '@features/vacancies/api/hooks';
import { VacancyForm, VacancyFormType } from '@features/vacancies/components/form';
import { Vacancy } from '@features/vacancies/types';
import { App, Button, Form, Space } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useManageVacancy = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.hooks' });
  const [form] = Form.useForm<VacancyFormType>();
  const { notification } = App.useApp();
  const drawer = useDrawer();

  const {
    isLoading: createIsLoading,
    mutate: createTemplate,
    isSuccess: createIsSuccess,
  } = useCreateVacancy();

  const {
    isLoading: updateIsLoading,
    mutate: updateTemplate,
    isSuccess: updateIsSuccess,
  } = useUpdateVacancy();

  const { mutate: assignTag } = useAssignEntityTag();

  const clearState = () => {
    form.resetFields();
    drawer.closeDrawer();
  };

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess) {
      clearState();
      notification.success({
        message: t('message'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createIsSuccess, updateIsSuccess]);

  return (type: FormActionType, item?: Vacancy, onSubmit?: (template: Vacancy) => void) => {
    item && form.setFieldsValue({ ...item, deadlineAt: dayjs(item.deadlineAt) });

    const onFinish = (values: VacancyFormType) => {
      switch (type) {
        case 'create':
          createTemplate({ ...values, deadlineAt: values.deadlineAt.toISOString() });
          break;
        case 'edit':
          item &&
            updateTemplate({
              vacancyId: values.id,
              input: { ...values, deadlineAt: values.deadlineAt.toISOString() },
            });
          break;
      }

      if (values.skillSet && values.skillSet.length > 0) {
        values.skillSet.forEach((tag) => {
          assignTag({
            entityId: values.id,
            entityType: 'vacancy',
            tagId: tag,
          });
        });
      }

      if (onSubmit) {
        onSubmit({
          ...values,
          deadlineAt: values.deadlineAt.toISOString(),
          totalCandidates: item?.totalCandidates ?? 0,
          totalInterviews: item?.totalInterviews ?? 0,
        });
      }
    };

    drawer.openDrawer({
      title: type === 'create' ? t('create-template') : t('edit-template'),
      extra: (
        <Space>
          <Button onClick={clearState}>{t('cancel')}</Button>
          <Button
            htmlType="submit"
            onClick={() => form.submit()}
            type="primary"
            loading={createIsLoading || updateIsLoading}
          >
            {type === 'create' ? t('submit') : t('save')}
          </Button>
        </Space>
      ),
      content: <VacancyForm form={form} onFinish={onFinish} />,
      size: 'large',
    });
  };
};

type FormActionType = 'create' | 'edit';
