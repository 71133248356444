import React, { useEffect, useState } from 'react';
import { EditOutlined, MessageOutlined } from '@ant-design/icons';
import { Tag } from '@features/tags';
import { Candidate } from '@features/vacancies/components/candidates/types';
import { ConductInterviewResultForm } from '@features/vacancies/components/interview/types';
import { InterviewQuestionResult } from '@features/vacancies/types';
import { useFeedbackGenerator } from '@openai/hooks';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  List,
  Progress,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

export interface ConductInterviewResultProps {
  form?: FormInstance<ConductInterviewResultForm>;
  onFinish?: (values: ConductInterviewResultForm) => void;
  candidate: Candidate;
  interviewQuestions: InterviewQuestionResult[];
  totalScore: number;
}

export const ConductInterviewResult: React.FC<ConductInterviewResultProps> = ({
  form,
  onFinish,
  candidate,
  interviewQuestions,
  totalScore,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.interview.form' });
  const [openQuestions, setOpenQuestions] = useState(false);
  const feedbackGenerator = useFeedbackGenerator();

  useEffect(() => {
    if (form && feedbackGenerator.feedback !== null) {
      form?.setFieldValue(['feedback', 'content'], feedbackGenerator.feedback);
    }
  }, [form, feedbackGenerator.feedback]);

  const renderActions = (item: InterviewQuestionResult) => {
    const actions = [<Tag key={'rate'}>{item.skipped ? t('skipped') : item.score}</Tag>];

    if (item.notes) {
      actions.push(
        <Tooltip key={'notes'} title={item.notes}>
          <MessageOutlined />
        </Tooltip>,
      );
    }

    return actions;
  };

  const generateFeedback = async () => {
    await feedbackGenerator.generate(interviewQuestions);
  };

  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Col>
        <Row gutter={16} justify={'space-between'}>
          <Col>
            <Typography.Paragraph type={'secondary'}>{t('candidate')}</Typography.Paragraph>
            <Typography.Paragraph>{candidate.name}</Typography.Paragraph>
          </Col>
          <Col>
            <Typography.Paragraph type={'secondary'}>{t('skipped-questions')}</Typography.Paragraph>
            <Typography.Paragraph>
              {interviewQuestions.filter((item) => item.skipped).length}
            </Typography.Paragraph>
          </Col>
        </Row>
        <Typography.Paragraph type={'secondary'}>{t('candidate-score')}</Typography.Paragraph>
        <Progress percent={totalScore} />

        <Button
          style={{ paddingLeft: 0 }}
          type={'link'}
          onClick={() => setOpenQuestions(!openQuestions)}
        >
          {openQuestions ? t('hide') : t('see')}
        </Button>

        {openQuestions && (
          <List
            style={{ marginTop: 16 }}
            dataSource={interviewQuestions}
            renderItem={(item) => (
              <List.Item actions={renderActions(item)}>
                <Typography.Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: true }}>
                  {item.question.question.content}
                </Typography.Paragraph>
              </List.Item>
            )}
          />
        )}

        <Form.Item name={['feedback', 'content']}>
          <Input.TextArea style={{ marginTop: 16 }} placeholder={t('add-feedback')} />
        </Form.Item>

        <Button
          icon={<EditOutlined />}
          loading={feedbackGenerator.isLoading}
          onClick={generateFeedback}
        >
          {t('generate-feedback')}
        </Button>
      </Col>
    </Form>
  );
};
