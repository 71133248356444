import React from 'react';
import { FavoriteIcon } from '@common/components/icon';
import { TagContainer } from '@features/tags/components/tag-container';
import { TemplateActions } from '@features/templates/components/common';
import { TemplateType } from '@features/templates/types';
import { formatDate } from '@utils/index';
import { Card, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface TemplateCardProps {
  template: TemplateType;
  setIsFavorite: (templateId: string) => void;
  editTemplate: (template: TemplateType) => void;
  deleteTemplate: (templateId: string) => void;
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  setIsFavorite,
  editTemplate,
  deleteTemplate,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'templates.card' });

  return (
    <Card
      size={'small'}
      title={template.title}
      extra={
        <FavoriteIcon
          isFavorite={template.isFavorite ?? false}
          onClick={() => setIsFavorite(template.id)}
        />
      }
    >
      <Row align={'middle'} style={{ marginBottom: 12 }}>
        <span style={{ fontWeight: '500', color: '#A8ACB2' }}>
          {t('question', { count: template.questions.length })}
        </span>
        <Divider type={'vertical'} />
        <span
          style={{
            fontWeight: '500',
            color: '#A8ACB2',
          }}
        >
          {template.createdAt && formatDate(template.createdAt, 'date-time')}
        </span>
      </Row>
      <div style={{ marginBottom: 12 }}>
        {template.questions.slice(0, 5).map((item, index) => (
          <Typography.Paragraph
            key={index}
            style={{
              marginBottom: 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {index + 1}. {item.question.content}
          </Typography.Paragraph>
        ))}
        {template.questions.length > 5 && (
          <Typography.Paragraph
            style={{
              marginBottom: 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              opacity: 0.5,
            }}
          >
            6. {template.questions[5].question.content}
          </Typography.Paragraph>
        )}
      </div>
      <Row justify={'space-between'}>
        <TagContainer entityType={'template'} entityId={template.id} />
        <TemplateActions
          onEditTemplate={() => editTemplate(template)}
          onRemoveTemplate={() => deleteTemplate(template.id)}
        />
      </Row>
    </Card>
  );
};
