import { RatingScaleEnum, RatingScaleOptionType } from '@features/templates/types';

export class RatingScaleOptionsFactory {
  create(type: RatingScaleEnum | string): RatingScaleOptionType[] {
    switch (type) {
      case RatingScaleEnum.PERCENT:
        return [
          {
            scale: 10,
            value: '10%',
          },
          {
            scale: 20,
            value: '20%',
          },
          {
            scale: 30,
            value: '30%',
          },
          {
            scale: 40,
            value: '40%',
          },
          {
            scale: 50,
            value: '50%',
          },
          {
            scale: 60,
            value: '60%',
          },
          {
            scale: 70,
            value: '70%',
          },
          {
            scale: 80,
            value: '80%',
          },
          {
            scale: 90,
            value: '90%',
          },
          {
            scale: 100,
            value: '100%',
          },
        ];
      case RatingScaleEnum.LEVEL:
        return [
          {
            scale: 10,
            value: 'Junior',
          },
          {
            scale: 50,
            value: 'Middle',
          },
          {
            scale: 100,
            value: 'Senior',
          },
        ];
      case RatingScaleEnum.POINTS:
        return [
          {
            scale: 10,
            value: '1',
          },
          {
            scale: 20,
            value: '2',
          },
          {
            scale: 30,
            value: '3',
          },
          {
            scale: 40,
            value: '4',
          },
          {
            scale: 50,
            value: '5',
          },
          {
            scale: 60,
            value: '6',
          },
          {
            scale: 70,
            value: '7',
          },
          {
            scale: 80,
            value: '8',
          },
          {
            scale: 90,
            value: '9',
          },
          {
            scale: 100,
            value: '10',
          },
        ];
      default:
        return [];
    }
  }
}
