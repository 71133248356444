import React from 'react';
import logoHorizontal from '@assets/logo-horizontal-dark.svg';
import { ProfileSection } from '@features/dashboard/components/profile';
import { Image, Input, Layout } from 'antd';

const { Header } = Layout;

const headerStyle = {
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const AppHeader: React.FC = () => (
  <Header style={headerStyle}>
    <Image style={{ maxHeight: '24px' }} src={logoHorizontal} preview={false} />
    <Input.Search disabled={true} style={{ maxWidth: '550px' }} />
    <ProfileSection />
  </Header>
);
