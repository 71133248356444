import React from 'react';
import { AppHeader } from '@features/dashboard/components/layout/header';
import { AppSider } from '@features/dashboard/components/layout/sider';
import { useAppStore } from '@store/index';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

const { Content } = Layout;

export const DashboardLayout = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.menu' });
  const space = useAppStore((state) => state.activeSpace);
  const location = useLocation();
  const selectedKey = location.pathname.split('/')[2];

  const items = [
    {
      key: 'home',
      label: <NavLink to={`/${space?.id}/home`}>{t('home')}</NavLink>,
    },
    {
      key: 'vacancies',
      label: <NavLink to={`/${space?.id}/vacancies`}>{t('vacancies')}</NavLink>,
    },
    {
      key: 'schedule',
      label: <NavLink to={`/${space?.id}/schedule`}>{t('schedule')}</NavLink>,
    },
    {
      key: 'templates',
      label: <NavLink to={`/${space?.id}/templates`}>{t('templates')}</NavLink>,
    },
  ];

  return (
    <Layout>
      <AppHeader />
      <Layout hasSider style={{ padding: '0 50px' }}>
        <AppSider selectedKeys={[selectedKey]} items={items} />
        <Content style={{ marginBottom: '16px', width: '100%', overflow: 'auto' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
