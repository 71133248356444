import React, { useEffect, useRef } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  MessageOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import { useGetInterview } from '@features/schedule/api/hooks/use-get-interview';
import { Tag } from '@features/tags';
import { useGetVacancy } from '@features/vacancies/api/hooks';
import { useGetCandidate } from '@features/vacancies/api/hooks/use-get-candidate';
import { useFinishInterviewForm } from '@features/vacancies/components/interview/hooks';
import { useAppStore } from '@store/index';
import { throwException } from '@utils/index';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  Progress,
  Rate,
  Result,
  Row,
  Spin,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const ConductInterview: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.interview' });
  const { token } = theme.useToken();
  const { interviewId } = useParams();
  const rowRef = useRef<number>(68);

  const { data: interview } = useGetInterview(interviewId ?? throwException(''));

  const { data: vacancy } = useGetVacancy(interview?.vacancyId);

  const { data: candidate } = useGetCandidate(interview?.candidateId);

  const finishInterview = useFinishInterviewForm();

  const navigate = useNavigate();

  const [
    currentQuestion,
    interviewQuestions,
    setCurrentQuestion,
    setInterviewQuestions,
    skipQuestion,
    nextQuestion,
    setQuestionRate,
    setQuestionNotes,
  ] = useAppStore((state) => [
    state.currentQuestion,
    state.interviewQuestions,
    state.setCurrentQuestion,
    state.setInterviewQuestions,
    state.skipQuestion,
    state.nextQuestion,
    state.setQuestionRate,
    state.setQuestionNotes,
  ]);

  useEffect(() => {
    const initialInterviewQuestions =
      interview?.interviewResult?.questions ||
      vacancy?.questions.map((item, index) => ({
        index: index,
        question: item,
        skipped: false,
        score: 0,
        notes: '',
      }));

    if (initialInterviewQuestions) {
      setInterviewQuestions(initialInterviewQuestions);
      setCurrentQuestion(initialInterviewQuestions[0]);
    }
  }, [interview, setCurrentQuestion, setInterviewQuestions, vacancy]);

  const calculateTotalScore = () => {
    let totalScore = 0;

    interviewQuestions.forEach((question) => {
      totalScore += question.score;
    });

    return Math.round(
      (totalScore / (interviewQuestions.filter((item) => !item.skipped).length * 10)) * 100,
    );
  };

  const addInterviewQuestion = () => {
    setInterviewQuestions([
      ...interviewQuestions,
      {
        index: interviewQuestions.length,
        question: {
          question: {
            content: '',
            fileIds: [],
          },
          answer: {
            content: '',
            fileIds: [],
          },
        },
        score: 0,
        skipped: false,
        notes: '',
      },
    ]);
  };

  const onFinishInterview = () => {
    if (interview && candidate) {
      finishInterview(
        interview.vacancyId,
        interview.id,
        candidate,
        interviewQuestions,
        calculateTotalScore(),
      );
    }
  };

  return vacancy && interview && candidate ? (
    <div
      style={{
        width: '100%',
        display: 'flex',
        height: `calc(100vh - ${rowRef.current}px)`,
        flexDirection: 'column',
      }}
    >
      <Row gutter={16} ref={(ref) => (rowRef.current = ref?.offsetHeight ?? rowRef.current)}>
        <Col span={24}>
          <Button
            style={{ paddingLeft: 0 }}
            type={'link'}
            icon={<LeftOutlined />}
            onClick={() => navigate(-1)}
          >
            {t('go-back')}
          </Button>
        </Col>
        <Col span={24} style={{ marginTop: 24 }}>
          <Card
            title={t('interview-title', { candidate: candidate?.name })}
            style={{ marginBottom: 16 }}
            extra={
              <Button type={'primary'} onClick={onFinishInterview}>
                {t('finish-interview')}
              </Button>
            }
          >
            <Descriptions
              layout={'vertical'}
              items={[
                {
                  key: 'candidate',
                  span: 1,
                  label: t('candidate'),
                  children: candidate?.name,
                },
                {
                  key: 'skipped',
                  label: t('skipped-questions'),
                  span: 1,
                  children: interviewQuestions.filter((item) => item.skipped).length,
                },
                {
                  key: 'score',
                  label: t('candidate-score'),
                  span: 1,
                  children: <Progress percent={calculateTotalScore()} />,
                },
                {
                  key: 'description',
                  span: 3,
                  label: t('description'),
                  children: vacancy.description?.content,
                },
              ]}
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card style={{ position: 'sticky', top: 16 }}>
            <Row gutter={16}>
              {currentQuestion ? (
                <>
                  <Col span={24}>
                    <Typography.Paragraph type={'secondary'}>
                      {t('current-question', {
                        current: (currentQuestion.index ?? 0) + 1,
                        total: interviewQuestions.length,
                      })}
                    </Typography.Paragraph>
                    <Typography.Paragraph strong={true}>
                      {(currentQuestion.index ?? 0) + 1}.{' '}
                      {currentQuestion.question.question.content}
                    </Typography.Paragraph>
                    <Typography.Text>{currentQuestion.question.answer?.content}</Typography.Text>

                    <Typography.Paragraph style={{ marginTop: 16 }}>
                      {t('score')}
                    </Typography.Paragraph>
                    <Rate
                      defaultValue={currentQuestion.score}
                      value={currentQuestion.score}
                      count={10}
                      style={{ color: '#000' }}
                      onChange={setQuestionRate}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      character={({ index, prefixCls }: { index: number }) => (
                        <Tag prefixCls={prefixCls}>{index + 1}</Tag>
                      )}
                    />
                  </Col>
                  <Col span={24} style={{ marginTop: 16 }}>
                    <Input.TextArea
                      value={currentQuestion.notes}
                      onChange={(e) => setQuestionNotes(e.target.value)}
                      placeholder={t('notes')}
                      rows={4}
                    />
                  </Col>
                  <Col span={24}>
                    <Row justify={'end'} style={{ marginTop: 24 }}>
                      <Button type={'link'} onClick={skipQuestion}>
                        {t('skip')}
                      </Button>
                      <Button type={'link'} onClick={nextQuestion}>
                        {t('next')}
                      </Button>
                    </Row>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  <Result
                    title={t('last-question')}
                    status={'success'}
                    extra={[
                      <Button type="primary" key="console" onClick={onFinishInterview}>
                        {t('finish-interview')}
                      </Button>,
                    ]}
                  />
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Button
            disabled={true}
            type="dashed"
            onClick={addInterviewQuestion}
            block
            icon={<PlusOutlined />}
          >
            {t('add-question')}
          </Button>
          {interviewQuestions.map((interviewQuestion, index) => (
            <ProCard
              key={index}
              title={
                <span
                  style={{
                    margin: 0,
                    color:
                      currentQuestion && currentQuestion.index === interviewQuestion.index
                        ? token.colorPrimaryText
                        : token.colorText,
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setCurrentQuestion(interviewQuestion);
                  }}
                >
                  {(interviewQuestion.index ?? 0) + 1}.{' '}
                  {interviewQuestion.question.question.content}
                </span>
              }
              size={'small'}
              defaultCollapsed={true}
              collapsible={true}
              bordered
              headerBordered
              style={{ marginTop: 16 }}
              headStyle={{ cursor: 'pointer', whiteSpace: 'normal' }}
              actions={[
                <Tag key="score">
                  {interviewQuestion.skipped && interviewQuestion.score == 0
                    ? t('skipped')
                    : interviewQuestion.score}
                </Tag>,
                <Tooltip key={'notes'} title={interviewQuestion.notes}>
                  <MessageOutlined />
                </Tooltip>,
                <EditOutlined key="edit" disabled={true} />,
                <DeleteOutlined key="delete" disabled={true} />,
              ]}
            >
              <Typography.Text>{interviewQuestion.question.answer?.content}</Typography.Text>
            </ProCard>
          ))}
        </Col>
      </Row>
    </div>
  ) : (
    <Spin />
  );
};
