import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Vacancy } from '@features/vacancies/types';
import { useAppStore } from '@store/index';
import { throwException } from '@utils/index';
import { Card, Descriptions } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export interface CardVacancyProps {
  item: Vacancy;
}

export const CardVacancy: React.FC<CardVacancyProps> = ({ item }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.card' });

  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is not found'),
  );
  const navigate = useNavigate();

  return (
    <Card
      size="small"
      onClick={() => navigate(`/${spaceId}/vacancies/${item.id}`)}
      title={item.title}
      extra={
        <Link to={`/${spaceId}/vacancies/${item.id}`}>
          <RightOutlined />
        </Link>
      }
      bordered={false}
    >
      <Descriptions size="small" layout="vertical">
        <Descriptions.Item label={t('deadline')}>
          {dayjs(item.deadlineAt).format('DD/MM/YYYY HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label={t('candidates')}>{item.totalCandidates}</Descriptions.Item>
        <Descriptions.Item label={t('interviews')}>{item.totalInterviews}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
