import { instance } from '@api/index';
import { PaginatedResponse } from '@common/api/output';
import { DEFAULT_PAGE_SIZE } from '@constants/index';
import { InterviewOutput } from '@features/schedule/api/output';

const ENDPOINT = 'interviews';

export async function loadInterviews(
  spaceId: string,
  page: number,
): Promise<PaginatedResponse<InterviewOutput>> {
  const offset = page * DEFAULT_PAGE_SIZE;

  const { data } = await instance.get<PaginatedResponse<InterviewOutput>>(
    `${spaceId}/${ENDPOINT}`,
    {
      params: {
        limit: DEFAULT_PAGE_SIZE,
        offset: offset,
      },
    },
  );

  return data;
}

export async function loadInterview(
  spaceId: string,
  interviewId: string,
): Promise<InterviewOutput> {
  const { data } = await instance.get<Promise<InterviewOutput>>(
    `${spaceId}/${ENDPOINT}/${interviewId}`,
  );

  return data;
}
