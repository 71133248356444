import { PaginatedResponse } from '@common/api/output';
import { TEMPLATES_QUERY_KEY } from '@constants/index';
import { setFavouriteTemplate } from '@features/templates/api';
import { TemplateOutput } from '@features/templates/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useSetFavouriteTemplate() {
  const queryClient = useQueryClient();
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id isn`t found'),
  );

  return useMutation<unknown, unknown, string, unknown>(
    (templateId) => setFavouriteTemplate(spaceId, templateId),
    {
      onSuccess: (_data, templateId) => {
        queryClient.setQueryData<InfiniteData<PaginatedResponse<TemplateOutput>>>(
          [TEMPLATES_QUERY_KEY],
          (data) =>
            data
              ? {
                  pages: data.pages.map((page) => ({
                    ...page,
                    data: page.data.map((template) =>
                      template.id === templateId
                        ? {
                            ...template,
                            isFavorite: !template.isFavorite,
                          }
                        : template,
                    ),
                  })),
                  pageParams: data.pageParams,
                }
              : data,
        );
      },
    },
  );
}
