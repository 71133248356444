import React from 'react';
import { StarFilled } from '@ant-design/icons';

interface FavoriteIconProps {
  isFavorite: boolean;
  onClick: () => void;
}

export const FavoriteIcon: React.FC<FavoriteIconProps> = ({ isFavorite, onClick }) => (
  <StarFilled style={{ color: isFavorite ? '#FFDF60' : '#A8ACB2' }} onClick={onClick} />
);
