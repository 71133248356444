import React from 'react';
import { useCreateTag } from '@features/tags/api/hooks/use-create-tag';
import { useGetTags } from '@features/tags/api/hooks/use-get-tags';
import { Select, SelectProps, Spin } from 'antd';
import { v4 } from 'uuid';

export interface TagSelectorProps extends SelectProps {
  onChange: (tag: string[] | undefined) => void;
}

export const TagSelector: React.FC<TagSelectorProps> = (props) => {
  const createTag = useCreateTag();

  const { isLoading, data } = useGetTags();

  const addItem = (name: string) => {
    const isAllowAddTag = !data
      ?.map((item) => item.name.toLocaleLowerCase())
      .includes(name.toLocaleLowerCase());

    if (isAllowAddTag) {
      createTag.mutate({
        id: v4().toString(),
        name: name,
        color: '#000000',
        entityId: null,
        entityType: null,
      });
    }
  };

  return (
    <Select
      {...props}
      mode={'tags'}
      options={data?.map((tag) => ({ value: tag.name, label: tag.name }))}
      onChange={(value: string[]) => {
        const selectedTagIds: string[] = [];

        data?.forEach((tag) => {
          if (
            value.map((item) => item.toLocaleLowerCase()).includes(tag.name.toLocaleLowerCase())
          ) {
            selectedTagIds.push(tag.id);
          }
        });

        props.onChange(selectedTagIds);
      }}
      filterOption={false}
      onSelect={addItem}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
    />
  );
};
