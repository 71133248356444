import { TAGS_QUERY_KEY } from '@constants/index';
import { loadTags } from '@features/tags/api';
import { useAppStore } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useGetTags() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  return useQuery({
    queryKey: [TAGS_QUERY_KEY],
    queryFn: async () => loadTags(spaceId),
  });
}
