import React from 'react';
import { Layout, Menu, MenuProps } from 'antd';

const { Sider } = Layout;

export const AppSider: React.FC<MenuProps> = ({
  defaultSelectedKeys,
  defaultOpenKeys,
  items,
  selectedKeys,
}) => (
  <Sider width="300" style={{ background: 'transparent' }}>
    <Menu
      style={{ width: 256, background: 'transparent', height: '100vh', padding: '50px 0' }}
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={selectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      items={items}
    />
  </Sider>
);
