import React, { useMemo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { CardVacancy } from '@features/vacancies/components/card';
import { Vacancy } from '@features/vacancies/types';
import { Col, Empty, Row, Spin } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

const rootStyles: React.CSSProperties = {
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  overflowY: 'auto',
};

export interface VacancyListProps {
  gutter: Gutter | [Gutter, Gutter];
  span: number | string;
  isLoading: boolean;
  hasMore: boolean;
  vacancies: Vacancy[];
  editVacancy?: (vacancy: Vacancy) => void;
  deleteVacancy?: (vacancyId: string) => void;
  loadVacancies: (page: number) => void;
}

export const VacancyList: React.FC<VacancyListProps> = ({
  gutter,
  span,
  isLoading,
  vacancies,
  hasMore,
  loadVacancies,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies.list' });
  const isEmpty = useMemo(() => !isLoading && !vacancies.length, [isLoading, vacancies.length]);

  return (
    <div style={rootStyles}>
      <InfiniteScroll
        initialLoad={true}
        loadMore={loadVacancies}
        hasMore={hasMore}
        useWindow={false}
      >
        <Row gutter={gutter}>
          {vacancies.map((item) => (
            <Col span={span} key={`vacancy-${item.id}`}>
              <CardVacancy item={item} />
            </Col>
          ))}

          {isLoading && (
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin indicator={<LoadingOutlined />} />
            </Col>
          )}

          {isEmpty && (
            <Col span={24}>
              <Empty description={t('empty')} />
            </Col>
          )}
        </Row>
      </InfiniteScroll>
    </div>
  );
};
