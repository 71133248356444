import { signIn } from '@features/auth/api';
import { SignInInput } from '@features/auth/api/input';
import { AuthTokenOutput } from '@features/auth/api/output';
import { useMutation } from '@tanstack/react-query';
import { useSignIn as useAuthKitSignIn } from 'react-auth-kit';

export function useSignIn() {
  const authKitSignIn = useAuthKitSignIn();

  return useMutation<AuthTokenOutput, unknown, SignInInput, unknown>(
    (data: SignInInput) => signIn(data),
    {
      onSuccess: (data) => {
        authKitSignIn({
          token: data.accessToken,
          tokenType: 'Bearer',
          refreshToken: data.refreshToken,
          expiresIn: 15,
          refreshTokenExpireIn: 1440,
          authState: {
            isLoggedIn: true,
          },
        });

        window.location.reload();
      },
    },
  );
}
