import { Space, User } from '@features/auth/domain';
import { AppStore } from '@store/types';
import { StateCreator } from 'zustand';

export interface AuthSlice {
  activeSpace: Space | undefined;
  user: User | undefined;
  setActiveSpace: (space: Space | undefined) => void;
  setUser: (user: User | undefined) => void;
}

export const createAuthSlice: StateCreator<AppStore, [], [], AuthSlice> = (set) => ({
  user: undefined,
  activeSpace: undefined,
  spaces: [],
  setUser: (user) =>
    set(() => ({
      user: user,
    })),
  setActiveSpace: (space) =>
    set(() => ({
      activeSpace: space,
    })),
});
