import { DEFAULT_PAGE_SIZE, INTERVIEWS_QUERY_KEY, VACANCIES_QUERY_KEY } from '@constants/index';
import { loadVacancyInterviews } from '@features/vacancies/api';
import { useAppStore } from '@store/index';
import { useInfiniteQuery } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useGetInterviews(vacancyId: string) {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const query = useInfiniteQuery({
    queryKey: [VACANCIES_QUERY_KEY, vacancyId, INTERVIEWS_QUERY_KEY],
    queryFn: async ({ pageParam = 0 }) => loadVacancyInterviews(spaceId, vacancyId, pageParam),
    getNextPageParam: (lastPage) => {
      const totalPages = Math.floor(lastPage.metadata.totalCount / DEFAULT_PAGE_SIZE);
      const actualPage = lastPage.metadata.offset / DEFAULT_PAGE_SIZE;
      return actualPage < totalPages ? actualPage + 1 : undefined;
    },
  });

  return {
    isLoading: query.isLoading,
    data: query.data?.pages.flatMap((item) => item.data) ?? [],
    hasNextPage: query.hasNextPage,
    fetchNextPage: query.fetchNextPage,
    totalCount: query.data?.pages.flatMap((item) => item.metadata.totalCount)[0] ?? 0,
  };
}
