export const formatDate = (dateString: string, format: 'date' | 'date-time' | 'time') => {
  const date = new Date(dateString);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  if (format == 'date') {
    return date.toLocaleDateString();
  }

  if (format == 'date-time') {
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, timeOptions)}`;
  }

  if (format == 'time') {
    return date.toLocaleTimeString(undefined, timeOptions);
  }
};

export function isEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every(isEmpty);
  } else if (typeof value === 'object') {
    return Object.values(value).every(isEmpty);
  }

  return false;
}

export const jsonReplacer = (_key: string, value: any) => {
  return isEmpty(value) ? null : value;
};

export const ISO_STRING = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

export function throwException(errorMessage: string): never {
  throw new Error(errorMessage);
}
