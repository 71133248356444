import { PaginatedResponse } from '@common/api/output';
import { VACANCIES_QUERY_KEY } from '@constants/index';
import { createVacancy } from '@features/vacancies/api';
import { VacancyInput } from '@features/vacancies/api/input';
import { VacancyOutput } from '@features/vacancies/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useCreateVacancy() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, VacancyInput, unknown>(
    (data: VacancyInput) => createVacancy(spaceId, data),
    {
      onSuccess: (_data, variables) => {
        const vacancy: VacancyOutput = {
          ...variables,
          totalCandidates: 0,
          totalInterviews: 0,
        };

        queryClient.setQueryData<InfiniteData<PaginatedResponse<VacancyOutput>>>(
          [VACANCIES_QUERY_KEY],
          (data) =>
            data
              ? {
                  pages: data.pages.map((page) => ({
                    metadata: {
                      ...page.metadata,
                      totalCount: +page.metadata.totalCount,
                      count: +page.metadata.count,
                    },
                    data: [...page.data, vacancy],
                  })),
                  pageParams: data.pageParams,
                }
              : data,
        );
      },
    },
  );
}
