import { useEffect } from 'react';
import { SPACE_QUERY_KEY } from '@constants/index';
import { loadSpaces } from '@features/auth/api';
import { Space } from '@features/auth/domain';
import { useAppStore } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

export function useGetSpace(): Space | null {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [activeSpace, setActiveSpace] = useAppStore((state) => [
    state.activeSpace,
    state.setActiveSpace,
  ]);

  const { isError, data } = useQuery([SPACE_QUERY_KEY], async () => loadSpaces(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isAuthenticated(),
  });

  useEffect(() => {
    if (isError) {
      setActiveSpace(undefined);
    }

    if (data) {
      if (data.length === 0) {
        navigate('/auth/spaces');
      } else {
        const space = activeSpace ?? data[0];
        setActiveSpace(space);
        navigate(`/${space.id}/home`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError]);

  return activeSpace ?? null;
}
