import { PaginatedResponse } from '@common/api/output';
import { VACANCIES_QUERY_KEY } from '@constants/index';
import { updateVacancy } from '@features/vacancies/api';
import { UpdateVacancyInput } from '@features/vacancies/api/input';
import { VacancyOutput } from '@features/vacancies/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useUpdateVacancy() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    {
      vacancyId: string;
      input: UpdateVacancyInput;
    },
    unknown
  >(({ vacancyId, input }) => updateVacancy(spaceId, vacancyId, input), {
    onSuccess: (_data, { vacancyId, input }) => {
      const updateVacancy = (vacancy: VacancyOutput) => ({
        ...vacancy,
        ...input,
      });

      queryClient.setQueryData<InfiniteData<PaginatedResponse<VacancyOutput>>>(
        [VACANCIES_QUERY_KEY],
        (infiniteData) =>
          infiniteData
            ? {
                pages: infiniteData.pages.map((page) => ({
                  ...page,
                  data: page.data.map((vacancy) =>
                    vacancy.id === vacancyId ? updateVacancy(vacancy) : vacancy,
                  ),
                })),
                pageParams: infiniteData.pageParams,
              }
            : infiniteData,
      );

      queryClient.setQueryData<VacancyOutput>([VACANCIES_QUERY_KEY, vacancyId], (vacancy) =>
        vacancy ? updateVacancy(vacancy) : vacancy,
      );
    },
  });
}
