import React from 'react';
import { FavoriteIcon } from '@common/components/icon';
import { DEFAULT_PAGE_SIZE } from '@constants/index';
import { TagContainer } from '@features/tags/components/tag-container';
import { TemplateActions } from '@features/templates/components/common';
import { TemplateType } from '@features/templates/types';
import { formatDate } from '@utils/index';
import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

interface TemplateTableProps {
  isLoading: boolean;
  totalCount: number;
  templates: TemplateType[];
  setIsFavorite: (templateId: string) => void;
  editTemplate: (template: TemplateType) => void;
  deleteTemplate: (templateId: string) => void;
  loadTemplates: (page: number, pageSize: number) => void;
}

export const TemplateTable: React.FC<TemplateTableProps> = ({
  isLoading,
  totalCount,
  templates,
  setIsFavorite,
  editTemplate,
  deleteTemplate,
  loadTemplates,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'templates.table' });

  const paginationConfig: TablePaginationConfig = {
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalCount,
    onChange: loadTemplates,
  };

  const columns: ColumnsType<TemplateType> = [
    {
      title: '',
      fixed: 'left',
      width: 50,
      align: 'center',
      render: (_, { id, isFavorite }) => (
        <FavoriteIcon isFavorite={isFavorite ?? false} onClick={() => setIsFavorite(id)} />
      ),
    },
    {
      title: t('title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('questions'),
      dataIndex: 'questions',
      key: 'questions',
      render: (_, { questions }) => questions.length,
    },
    {
      title: t('created-at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, { createdAt }) => createdAt && formatDate(createdAt, 'date-time'),
    },
    {
      title: t('tags'),
      dataIndex: 'tags',
      key: 'tags',
      render: (_, { id }) => <TagContainer entityType={'template'} entityId={id} />,
    },
    {
      title: '',
      fixed: 'right',
      render: (_, item) => (
        <TemplateActions
          onEditTemplate={() => editTemplate(item)}
          onRemoveTemplate={() => deleteTemplate(item.id)}
        />
      ),
    },
  ];

  return (
    <Table
      id={'template-table'}
      size={'small'}
      scroll={{
        y: 'calc(100vh - 250px)',
      }}
      loading={isLoading}
      pagination={paginationConfig}
      columns={columns}
      dataSource={templates.map((item) => ({ ...item, key: `template-${item.id}` }))}
    />
  );
};
