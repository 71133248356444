import { ViewType } from '@features/templates/types';
import { AppStore } from '@store/types';
import { StateCreator } from 'zustand';

export interface TemplateSlice {
  templateViewType: ViewType;
  setTemplateViewType: (viewType: ViewType) => void;
}

export const createTemplateSlice: StateCreator<AppStore, [], [], TemplateSlice> = (set) => ({
  templateViewType: 'table',
  setTemplateViewType: (viewType) => set(() => ({ templateViewType: viewType })),
});
