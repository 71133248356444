import * as React from 'react';
import logo from '@assets/logo.svg';
import { SignInForm } from '@features/auth/components/sign-in';
import { SignUpForm } from '@features/auth/components/sign-up';
import { Avatar, Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface AuthenticationFormProps {
  activeTabKey: string | 'sign-in' | 'sign-up';
}

export const AuthenticationForm: React.FC<AuthenticationFormProps> = ({ activeTabKey }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const items: TabsProps['items'] = [
    {
      key: 'sign-in',
      label: <NavLink to={'/auth/sign-in'}>{t('sign-in')}</NavLink>,
      children: <SignInForm />,
    },
    {
      key: 'sign-up',
      label: <NavLink to={'/auth/sign-up'}>{t('sign-up')}</NavLink>,
      children: <SignUpForm />,
    },
  ];

  return (
    <>
      <Avatar src={logo} style={{ display: 'block', margin: '0 auto' }} />
      <Tabs
        defaultActiveKey={activeTabKey}
        centered
        items={items}
        style={{
          minWidth: '400px',
          maxWidth: '400px',
        }}
      />
    </>
  );
};
