import * as React from 'react';
import { useSignUp } from '@features/auth/api/hooks';
import { SignUpInput } from '@features/auth/api/input';
import { Button, Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const SignUpForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const signUp = useSignUp();
  const [form] = Form.useForm<SignUpInput>();

  const onFinish = async (values: SignUpInput) => {
    signUp.mutate(values);
  };

  return (
    <Form
      name="normal_sign_up"
      layout="vertical"
      form={form}
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
      }}
      onFinish={onFinish}
      requiredMark={false}
    >
      <Form.Item
        name="email"
        label={t('email')}
        rules={[
          {
            type: 'email',
            message: t('email-is-not-valid'),
          },
          {
            required: true,
            message: t('email-is-required'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('password')}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('password-is-required'),
          },
        ]}
      >
        <Input.Password type="password" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label={t('confirm-password')}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('confirm-password-is-required'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('confirm-password-is-didnt-match')));
            },
          }),
        ]}
      >
        <Input.Password type="password" />
      </Form.Item>

      <Typography.Paragraph style={{ textAlign: 'center' }} hidden={true}>
        By clicking Agree & Sign Up, you agree to the{' '}
        <Typography.Link>DevView User Agreement</Typography.Link>,{' '}
        <Typography.Link>Privacy Policy</Typography.Link>, and{' '}
        <Typography.Link>Cookie Policy</Typography.Link>.
      </Typography.Paragraph>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          className="sign-up-form-button"
          loading={signUp.isLoading}
        >
          {t('sign-up-action')}
        </Button>
      </Form.Item>
    </Form>
  );
};
