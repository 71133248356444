import { refreshJwtToken } from '@features/auth/api';
import { jsonReplacer } from '@utils/index';
import { notification } from 'antd';
import axios, { InternalAxiosRequestConfig } from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_BASE_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...axios.defaults.transformRequest,
    (data) => {
      if (data) {
        const parsed = JSON.parse(data);
        return JSON.stringify(parsed, jsonReplacer);
      }

      return data;
    },
  ],
});

interface Config extends InternalAxiosRequestConfig<never> {
  retry?: boolean;
}

instance.interceptors.request.use(
  async (configuration: Config) => {
    if (!configuration.headers?.Authorization || configuration.retry) {
      const accessToken = localStorage.getItem('_auth');

      if (accessToken) {
        configuration.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return configuration;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const authRefreshToken = localStorage.getItem('_auth_refresh');

    const originalConfig = error.config;

    if (!originalConfig) {
      return Promise.reject(error);
    }

    originalConfig.retry = true;

    if (error.response) {
      const { response } = error;

      switch (response.status) {
        case 500: {
          notification.error({
            message: 'Something went wrong 😮',
            description: 'Please try again after 5 minutes.',
          });

          return Promise.reject(error);
        }
        case 401: {
          if (!originalConfig.retry) {
            try {
              originalConfig.retry = true;

              if (!authRefreshToken) {
                localStorage.clear();
                return;
              }

              const { accessToken, refreshToken } = await refreshJwtToken(authRefreshToken);

              localStorage.setItem('_auth', accessToken);
              localStorage.setItem('_auth_refresh', refreshToken);

              return instance.request(originalConfig);
            } catch (err) {
              localStorage.clear();
              return Promise.reject(err);
            }
          }

          return Promise.reject(error);
        }
        case 409:
        case 403:
        case 400: {
          notification.error({
            message: 'Something went wrong 😮',
            description: error.response?.data.detail,
          });

          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    }

    if (error.request) {
      notification.error({
        message: 'Something went wrong 😮',
        description: 'Please try again after 5 minutes.',
      });

      throw error;
    }

    return Promise.reject(error);
  },
);
