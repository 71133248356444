import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { CardInterview } from '@features/schedule/components/card';
import { Interview } from '@features/schedule/types';
import { Col, Empty, List, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export interface InterviewListProps {
  isLoading: boolean;
  items: Interview[] | undefined;
}

export const InterviewList: React.FC<InterviewListProps> = ({ isLoading, items }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'schedule.list' });

  const groupedInterviews: Record<string, Interview[]> | undefined = items?.reduce(
    (groups, interview) => {
      const date = dayjs(interview.startAt).format('DD/MM/YYYY');

      if (!groups[date]) {
        groups[date] = [];
      }

      groups[date].push(interview);

      return groups;
    },
    {} as Record<string, Interview[]>,
  );

  return (
    <Row gutter={[16, 16]}>
      {isLoading ? (
        <Col span={24} style={{ textAlign: 'center' }}>
          <Spin indicator={<LoadingOutlined />} />
        </Col>
      ) : items && items.length && groupedInterviews ? (
        Object.entries(groupedInterviews).map(([date, interviews], index) => (
          <Col key={index} span={24}>
            <List
              itemLayout={'horizontal'}
              dataSource={interviews}
              size={'small'}
              header={date}
              renderItem={(item, index) => <CardInterview key={index} item={item} />}
            />
          </Col>
        ))
      ) : (
        <Col span={24}>
          <Empty description={t('empty')} />
        </Col>
      )}
    </Row>
  );
};
