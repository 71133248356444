import { PaginatedResponse } from '@common/api/output';
import { CANDIDATES_QUERY_KEY, VACANCIES_QUERY_KEY } from '@constants/index';
import { deleteVacancyCandidate } from '@features/vacancies/api';
import { CandidateOutput, VacancyOutput } from '@features/vacancies/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useDeleteCandidate() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { vacancyId: string; candidateId: string }, unknown>(
    ({ vacancyId, candidateId }) => deleteVacancyCandidate(spaceId, vacancyId, candidateId),
    {
      onSuccess: (_data, { vacancyId, candidateId }) => {
        const updateVacancy = (vacancy: VacancyOutput): VacancyOutput => ({
          ...vacancy,
          totalCandidates: -vacancy.totalCandidates,
        });

        queryClient.invalidateQueries({ queryKey: [CANDIDATES_QUERY_KEY] });

        queryClient.setQueryData<InfiniteData<PaginatedResponse<CandidateOutput>>>(
          [VACANCIES_QUERY_KEY, vacancyId, CANDIDATES_QUERY_KEY],
          (infiniteData) =>
            infiniteData
              ? {
                  pages: infiniteData.pages.map((page) => ({
                    ...page,
                    data: page.data.filter((candidate) => candidate.id !== candidateId),
                  })),
                  pageParams: infiniteData.pageParams,
                }
              : infiniteData,
        );

        queryClient.setQueryData<InfiniteData<PaginatedResponse<VacancyOutput>>>(
          [VACANCIES_QUERY_KEY],
          (infiniteData) =>
            infiniteData
              ? {
                  pages: infiniteData.pages.map((page) => ({
                    ...page,
                    data: page.data.map((vacancy) =>
                      vacancy.id === vacancyId ? updateVacancy(vacancy) : vacancy,
                    ),
                  })),
                  pageParams: infiniteData.pageParams,
                }
              : infiniteData,
        );

        queryClient.setQueryData<VacancyOutput>([VACANCIES_QUERY_KEY, vacancyId], (vacancy) =>
          vacancy ? updateVacancy(vacancy) : vacancy,
        );
      },
    },
  );
}
