import { VACANCIES_QUERY_KEY } from '@constants/index';
import { loadVacancy } from '@features/vacancies/api';
import { useAppStore } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useGetVacancy(vacancyId: string | undefined) {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  return useQuery({
    queryKey: [VACANCIES_QUERY_KEY, vacancyId],
    queryFn: async () =>
      loadVacancy(spaceId, vacancyId ?? throwException('Vacancy id is undefined')),
    enabled: !!vacancyId,
  });
}
