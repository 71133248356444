import { PaginatedResponse } from '@common/api/output';
import { CANDIDATES_QUERY_KEY, VACANCIES_QUERY_KEY } from '@constants/index';
import { addVacancyCandidate } from '@features/vacancies/api';
import { AddVacancyCandidateInput } from '@features/vacancies/api/input';
import { CandidateOutput, VacancyOutput } from '@features/vacancies/api/output';
import { useAppStore } from '@store/index';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useAddCandidate() {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { vacancyId: string; data: AddVacancyCandidateInput },
    unknown
  >(({ vacancyId, data }) => addVacancyCandidate(spaceId, vacancyId, data), {
    onSuccess: (_data, { vacancyId, data }) => {
      const updateVacancy = (vacancy: VacancyOutput): VacancyOutput => ({
        ...vacancy,
        totalCandidates: +vacancy.totalCandidates,
      });

      queryClient.invalidateQueries({ queryKey: [CANDIDATES_QUERY_KEY] });

      queryClient.setQueryData<InfiniteData<PaginatedResponse<CandidateOutput>>>(
        [VACANCIES_QUERY_KEY, vacancyId, CANDIDATES_QUERY_KEY],
        (infiniteData) =>
          infiniteData
            ? {
                pages: infiniteData.pages.map((page) => ({
                  ...page,
                  data: [...page.data, { ...data, interviewResults: [] }],
                })),
                pageParams: infiniteData.pageParams,
              }
            : infiniteData,
      );

      queryClient.setQueryData<InfiniteData<PaginatedResponse<VacancyOutput>>>(
        [VACANCIES_QUERY_KEY],
        (infiniteData) =>
          infiniteData
            ? {
                pages: infiniteData.pages.map((page) => ({
                  ...page,
                  data: page.data.map((vacancy) =>
                    vacancy.id === vacancyId ? updateVacancy(vacancy) : vacancy,
                  ),
                })),
                pageParams: infiniteData.pageParams,
              }
            : infiniteData,
      );

      queryClient.setQueryData<VacancyOutput>([VACANCIES_QUERY_KEY, vacancyId], (vacancy) =>
        vacancy ? updateVacancy(vacancy) : vacancy,
      );
    },
  });
}
