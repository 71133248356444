import React, { useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useGetVacancies } from '@features/vacancies/api/hooks';
import { VacancyList } from '@features/vacancies/components/list';
import { useManageVacancy } from '@features/vacancies/hooks';
import { Button, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const Vacancies = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'vacancies' });
  const rowRef = useRef<number>(68);
  const openManageVacancyForm = useManageVacancy();

  const { isLoading, data: vacancies, hasNextPage, fetchNextPage } = useGetVacancies();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        height: `calc(100vh - ${rowRef.current}px)`,
        flexDirection: 'column',
      }}
    >
      <Row
        ref={(ref) => (rowRef.current = ref?.offsetHeight ?? rowRef.current)}
        align={'middle'}
        justify={'space-between'}
      >
        <Typography.Title level={3} style={{ display: 'flex' }}>
          {t('title')}
          <Button
            type={'link'}
            icon={<PlusOutlined />}
            onClick={() => openManageVacancyForm('create')}
          >
            {t('add-vacancy')}
          </Button>
        </Typography.Title>
      </Row>

      <VacancyList
        gutter={[16, 16]}
        span={24}
        loadVacancies={() => fetchNextPage()}
        hasMore={hasNextPage}
        isLoading={isLoading}
        vacancies={vacancies}
      />
    </div>
  );
};
