import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Tag } from '@features/tags';
import { useAssignEntityTag } from '@features/tags/api/hooks/use-assign-entity-tag';
import { useDeleteEntityTag } from '@features/tags/api/hooks/use-delete-entity-tag';
import { useEntityTags } from '@features/tags/api/hooks/use-entity-tags';
import { TagSelector } from '@features/tags/components/selector';
import { Popover, Space, Spin, theme } from 'antd';
import { useTranslation } from 'react-i18next';

export interface TagContainerProps {
  entityType: 'vacancy' | 'template' | 'question';
  entityId: string;
}

export const TagContainer: React.FC<TagContainerProps> = ({ entityType, entityId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'tags' });
  const { token } = theme.useToken();
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const { isLoading, data } = useEntityTags(entityType, { entityIds: [entityId] });
  const assignTag = useAssignEntityTag();
  const deleteTag = useDeleteEntityTag();

  const handleSelectConfirm = () => {
    if (selectedTags.length > 0) {
      selectedTags.forEach((item) => {
        if (!data?.map((value) => value.id).includes(item)) {
          assignTag.mutate({
            entityId: entityId,
            entityType: entityType,
            tagId: item,
          });
        }
      });

      setSelectedTags([]);
    }
  };

  const handleRemoveTag = (itemId: string) => {
    deleteTag.mutate({
      entityId: entityId,
      entityType: entityType,
      tagId: itemId,
    });
  };

  return (
    <Space size={[0, 8]} wrap>
      {isLoading && <Spin />}

      {data?.map((item, index) => (
        <Tag key={index} closable={true} onClose={() => handleRemoveTag(item.id)}>
          {item.name}
        </Tag>
      ))}

      <Popover
        overlayStyle={{
          minWidth: 150,
        }}
        content={
          <TagSelector
            style={{
              width: '100%',
            }}
            onChange={(value) => {
              value && setSelectedTags([...selectedTags, ...value]);
            }}
          />
        }
        onOpenChange={(visible) => {
          if (!visible) {
            handleSelectConfirm();
          }
        }}
        arrow={false}
        trigger={'click'}
      >
        <Tag
          icon={<PlusOutlined />}
          style={{
            background: token.colorBgContainer,
            borderStyle: 'dashed',
          }}
          bordered={true}
        >
          {t('add-tag')}
        </Tag>
      </Popover>
    </Space>
  );
};
