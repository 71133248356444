import { ENTITY_TAGS_QUERY_KEY } from '@constants/index';
import { loadEntityTags } from '@features/tags/api';
import { GetEntitiesTagsInput } from '@features/tags/api/input';
import { useAppStore } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { throwException } from '@utils/index';

export function useEntityTags(entityType: string, input: GetEntitiesTagsInput) {
  const spaceId = useAppStore(
    (state) => state.activeSpace?.id ?? throwException('Space id is undefined'),
  );

  return useQuery({
    queryKey: [ENTITY_TAGS_QUERY_KEY, entityType, ...input.entityIds],
    queryFn: async () => loadEntityTags(spaceId, entityType, input),
  });
}
