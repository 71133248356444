import React from 'react';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Space } from 'antd';

interface TemplateActionsProps {
  onEditTemplate: () => void;
  onRemoveTemplate: () => void;
}

export const TemplateActions: React.FC<TemplateActionsProps> = ({
  onEditTemplate,
  onRemoveTemplate,
}) => (
  <Space size={16}>
    <EditFilled style={{ color: '#7B818E' }} onClick={onEditTemplate} />
    <DeleteFilled style={{ color: '#7B818E' }} onClick={onRemoveTemplate} />
  </Space>
);
